import React from "react";
import styled from "styled-components";
import Mathworks from "../../images/sponsors/mathworks.svg";

/* Might be better for this page if text is centred. Copied original Wrapper but aligned text to center*/
const Wrapper = styled.div`
  border-top: black solid 1px;
  margin: 0 15%;
  padding-bottom: 10%;

  a {
    color: blue;
    z-index: 2;
    padding-bottom: 5%;
    text-decoration: none;

    &:hover {
      color: black;
    }
  }

  p {
    white-space: pre-wrap;
    margin-bottom: 5%;
  }

  li {
    list-style: circle;
    list-style-position: inside;
  }

  h5 {
    margin-bottom: 5%;
  }

  img {
    max-width: 100%;
    max-height: 60vh;
  }

  @media (max-width: 900px) {
    h5 {
      margin-bottom: 10%;
    }

    padding-bottom: 15%;
  }
`;

const MathWorksResources = () => {
  return (
    <Wrapper>
      <h1>MathWorks Resources</h1>
      <div style={{ marginTop: "5%", marginBottom: "5%" }}>
        <img src={Mathworks} alt="Mathworks" />
      </div>
        <p className="left" style={{ marginBottom: "0" }}>
          MathWorks is the company behind Matlab and Simulink, and supply a lot of support for the Science and Engineering industries. Their website
          provides a great deal of support and a community platform for students, enthusiasts and professions to grow and share their knowledge.
        </p>
      
      <h2 style={{ marginTop: "3%" }}>Mathworks Adoption Prize</h2>
        <p className="centre" style={{ marginBottom: "1%" }}>In 2021 Mathworks is again offering a prize for the best usage of Matlab and Simulink.</p>
      <a href="/drc/matlab-adoption-prize-rules" target="_blank" rel="noreferrer">Mathworks Prize Rules</a>
      
      <h2 style={{ marginTop: "3%" }}>Landing Page</h2>
        <p className="centre" style={{ marginBottom: "1%" }}>MathWorks has put together a great resource page to assist teams in Droid Racing competitions.</p>
      <a href="https://au.mathworks.com/academia/student-competitions.html" target="_blank" rel="noreferrer">Check it out here!</a>
      
      <h2 style={{ marginTop: "3%" }}>Demo Packet</h2>
        <p className="centre" style={{ marginBottom: "1%" }}>They have also provided a demo packet for vehicle modeling and Computer vision.</p>
      <a href="https://www.dropbox.com/sh/evsmd5hc1z0novn/AABKXS6d1ocbEbemfEysxth1a?dl=0" target="_blank" rel="noreferrer">Download their zip file from Dropbox here!</a>
    </Wrapper>
  );
};

export default MathWorksResources;
