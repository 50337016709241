import ThankYou_Pic from "../images/ThankYou_Rover.jpg";

const ThankYou = () => {
    return <div className='thank_you'>
        <h1>Thank you!</h1>
        <p>Thanks for reaching out, we'll get back to you shortly!</p>
        <p>
            Meanwhile, you can check out our <a href={'https://linktr.ee/qutroboticsclub'}>LinkTree</a>&nbsp;
            for links to our latest events and to follow us on social media.
        </p>
        <a href={'/'}>Go back to home</a>

        <h3 style={{ marginTop: "3%" }}> </h3>
        <img src={ThankYou_Pic}  className="img" alt="Thank You" height="1000" width="700"></img>
    </div>
}

export default ThankYou