import React from "react";
import styled from "styled-components";
//DRC Rules PDF
import read_more from "../../db/DRC_Rules_2024.pdf"


const Wrapper = styled.div`
  border-top: black solid 1px;
  margin: 0 15%;
  padding-bottom: 10%;

  a {
    color: blue;
    z-index: 2;
    padding-bottom: 5%;
    text-decoration: none;

    &:hover {
      color: black;
    }
  }

  p {
    white-space: pre-wrap;
    margin-bottom: 5%;
  }

  li {
    list-style: circle;
    list-style-position: inside;
    text-align: left;
    margin-bottom: 1%;
  }

  @media (max-width: 900px) {
    padding-bottom: 15%;
    li {
      margin-bottom: 5%;
    }
  }
`;

const Rules = () => {
  return (
    // Display PDF embedded in page or show "Unable to display PDF file."
    <Wrapper>
      <h1>DRC 2024 Rules</h1>
      <h4 style={{ color: "red" }}>Rev 2.0 04/04/2024</h4>

      <object data={read_more} type="application/pdf" width="80%" height="1000px">
        <p>Unable to display PDF file. <a href={read_more}>Download</a> instead.</p>
      </object> 
    </Wrapper>
  );
};

export default Rules;
