import React from "react";
import { Wrapper } from "../../components/Wrapper.styles";
import Banner from "../../images/resource_links/Drc_2016_banner.jpg";
import QUT_Lawn from "../../images/resource_links/Qut_lawn.jpg";

const InauguralDrc = () => {
  return (
    <Wrapper>
      <h1>Droid Racing Challenge 2016</h1>
      <img src={Banner} alt="Banner" />

      <p style={{ marginBottom: "1%" }}>
        The QUT Droid Racing Challenge is now over! Thank you to all competitors
        and attendees for making it such a great event. Look out for next year
        when the club hopes to run the challenge again! In the meantime, check
        out our wrap up and technical articles about the 2016 challenge: wrap up
        and technical
      </p>
      
      <div>
        <a href="/drc/faqs" target="_blank" rel="noreferrer">FAQS{" "}</a>
        |
        <a href="/drc/rules" target="_blank" rel="noreferrer">{" "}Rules{" "}</a>
        |
        <a href="/drc/autonomous-vehicle-guide" target="_blank" rel="noreferrer">{" "}Technical Advice{" "}</a>
      </div>

      <h2>Introduction</h2>
      <h3><a href="qutrc-website/src/pages/drc/inaugural_drc">Why do we need robots that can see?</a></h3>
      <img src={QUT_Lawn} alt="QUT_Lawn" />
      <h5>
        View of the the Kidney Lawn in front of Old Government House at QUT Gardens Point Campus. Droids will race around the paved surface surrounding the lawn. Image:{" "}
        <a href="qutrc-website/src/pages/drc/inaugural_drc" alt="OGH link">http://www.ogh.qut.edu.au/venue-hire/</a>
      </h5>
      
      <p>
        The QUT Droid Racing Challenge (DRC) is a brand new project QUT Robotics
        Club is launching this year. The DRC is a student robotics challenge
        focusing on the development of ground based autonomous robotic racing
        platforms. The “droids” that take part in this competition will feature
        autonomous operation, robotic vision, navigation and obstacle avoidance,
        and mechanical design optimized for speed. The Challenge has been set so
        that a small team of undergraduate students should be able to complete a
        capable robot in one semester. The final competition will take place on
        the 29th of June around the Kidney Lawn at QUT Gardens Point Campus, in
        front of Old Government House. Prizes will be at stake for winning
        teams. Universities from across Australia and New Zealand were invited
        to participate.
      </p>

      <h2>The Challenge:</h2>
      <ul>
        <li>Design – Build an autonomous ground based droid using any desired method and materials.</li>
        <li>Task – Race around the paved path surrounding the Kidney Lawn at QUT Gardens Point.</li>
        <li>Computer Vision – Use computer vision to detect track lines and obstacles.</li>
        <li>Obstacle avoidance – Avoid any obstacles in the droid’s path, and other droids.</li>
        <li>Creativity – Come up with novel designs and approaches to create the fastest droid racer.</li>
        <li>Aesthetics – Make your robot look awesome.</li>
      </ul>
      
      <h2>Key Dates:</h2>
      <ul>
        <li>22 February 2016: Introduce the competition, invite teams, and applications opened for the challenge.</li>
        <li>7 March: Detailed competition requirements and rules published, challenge is officially open.</li>
        <li>1st April: Applications due</li>
        <li>3rd June: Progress update due</li>
        <li>28th June: Practice and testing day for teams on the race track at QUT</li>
        <li>29th June: Final Challenge at QUT</li>
      </ul>

      <p>
        Participation in the final will be subject to meeting all rules and
        requirements. The progress update is simply a check up to make sure that
        these will be met by the day of the challenge.
      </p>
    </Wrapper>
  );
};

export default InauguralDrc;
