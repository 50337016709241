import React from "react";
import drc_header from "../images/drc-header-1.png";

import "../css/drc.css";

const DRC = () => {
  return (
    <div className="drc-page page-container">
      <h1>Droid Racing Challenge</h1>
      <img className="img" src={drc_header} alt="DRC-Header" />

      <section id="drc-links">
        <a id="current-competition" className="pretty-link" href="drc/2024">
          Current competition
        </a>
        <br />

        <a id="rules" className="pretty-link" href="/drc/rules">
          Rules
        </a>
        <span>|</span>
        <a id="rules" className="pretty-link" href="/drc/faqs">
          FAQ
        </a>
        <span>|</span>
        <a id="competition-format" className="pretty-link" href="/drc/competition-format/">
          Competition Format
        </a>
      </section>

      <hr />

      <section id="droid-racing">
        <h2>Droid Racing</h2>
        <p className="left">
          The Droid Racing Challenge (DRC) is a student robotics challenge
          focusing on the development of ground-based autonomous robotic racing
          platforms. The “droids” that take part in this competition will
          feature autonomous operation, robotic vision, navigation and obstacle
          avoidance, and mechanical design optimised for speed. The Challenge
          has been set so that a small team of undergraduate students should be
          able to complete a capable robot in one semester.
        </p>
      </section>

      <hr />

      <section id="the-challenge">
        <h2>The Challenge:</h2>
        <p className="left">
          Design and build a completely autonomous system capable of racing
          around a track, denoted by two coloured lines; all while avoiding
          collisions with other vehicles and obstacles. This is done through the
          use of computer vision. By leaving the rest of the robot design open
          ended it is hoped that teams will find creative and innovative
          solutions to the problem.
        </p>
      </section>

      <hr />

      <section id="previous-years">
        <h2>Previous Years:</h2>
        <a className="pretty-link" href="drc/2023">
          Droid Racing Challenge 2023
        </a>
        <a className="pretty-link" href="drc/2022">
          Droid Racing Challenge 2022
        </a>
        <a className="pretty-link" href="drc/2021">
          Droid Racing Challenge 2021
        </a>
        <a className="pretty-link" href="drc/2020">
          Droid Racing Challenge 2020
        </a>
        <a className="pretty-link" href="drc/2019">
          Droid Racing Challenge 2019
        </a>
        <a className="pretty-link" href="drc/2018">
          Droid Racing Challenge 2018
        </a>
        <a className="pretty-link" href="drc/2017">
          Droid Racing Challenge 2017
        </a>
        <a className="pretty-link" href="drc/2016">
          Droid Racing Challenge 2016
        </a>
      </section>
    </div>
  );
};

export default DRC;
