import React from "react";
import styled from "styled-components";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import DRC from "..//../../images/drc-header-1.png";
import QUTRCLogo from "..//../../images/qutrc_smalllogo.png";
import "../../../css/drc.css";
import Mathworks from "../../../images/sponsors/mathworks.svg";
import DRCResult from "../../../images/drc/DRC_2023_Results.png";
import Boeing from "../../../images/sponsors/boeing.svg";
import QCR from "../../../images/sponsors/qcr.svg";
import WatkinsSteel from "../../../images/sponsors/Watkins_Steel.png";

const Wrapper = styled.div`
  border-top: black solid 1px;
  padding-bottom: 10%;
  a {
    color: blue;
    z-index: 2;
    padding-bottom: 1%;
    text-decoration: none;

    &:hover {
      color: black;
    }
  }

  p {
    white-space: pre-wrap;
    margin-bottom: 3%;
    text-align: left;
  }

  li {
    list-style: circle;
    list-style-position: inside;
    text-align: left;
  }

  img {
    max-width: 100%;
    max-height: 60vh;
  }

  @media (max-width: 900px) {
    padding-bottom: 15%;
  }
`;

/* Can't use numbers e.g. 2021 */
const TwentyTwentyThree = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isXlDesktop = useMediaQuery(theme.breakpoints.only("xl"));
  return (
    <Wrapper className="drc-page page-container">
      <h1>Droid Racing Challenge 2023</h1>
      <div>
        <img src={DRC} alt="DRC-Header" />
      </div>

      <Stack direction="row" alignItems="center" justifyContent="center">
        <div>
          <img src={QUTRCLogo} alt="DRC-Header" height={"200px"} />
        </div>
        <div>
          <ul>
            <li><a className="pretty-link" href="https://docs.google.com/forms/d/e/1FAIpQLSfWyHnb8gHl_DdLAvKN9pJ9Vk0pbuYFfYw4n8T0J77k5xqKow/viewform">Expression of Interest</a></li>
            <li><a className="pretty-link" href="/drc/rules">Rules</a></li>
            <li><a className="pretty-link" href="/sponsors">Sponsors</a></li>
            <li><a className="pretty-link" href="map">Directional Map</a></li>
          </ul>
        </div>
      </Stack>
      <hr/>

        <p>
          QUT Robotics Club has hosted the Droid Racing Challenge (est. 2016), a friendly competition where universities 
          from around Australia and the world are invited to enter an autonomous droid to race against other teams. 
          <br/><br/>
          The 2023 Competition was hosted on the 11th and 12th of July (testing and competition days respectively). 
          The event welcomed 15 teams from QLD and NSW, and saw amazing races and another successful stream to an international 
          audience. 2 of these teams were comprised of high-schoolers, who battled our university teams to win. We hope to see 
          more teams in the future!
        </p>

        <img src={DRCResult} alt="DRC 2023 Results" />

        <p style={{ marginTop: "5%" }}>Registration's opens 28th March.</p>
        <p style={{ marginBottom: "1%" }}>Link to other photos:</p>
        <ul>
          <li><a href="https://www.facebook.com/media/set/?vanity=shaunmoonphotography&set=a.570073848180206">2022 DRC</a></li>
          <li><a href="https://drive.google.com/drive/folders/1zWRN-TpXOqCepwuu9eCkagXH7nGucFfk?usp=sharing">2021 DRC QLD Event</a></li>
        </ul>


        <p style={{ marginTop: "3%" }}>Best of luck to all teams!</p>
        <p>
          Make sure to follow our{" "}
          <a href="http://www.facebook.com/qutroboticsclub">Facebook</a> for the
          latest information. Alternatively join our{" "}
          <a href="https://discord.gg/cQa8VzVgT4">Discord</a> in the DRC Specific
          Channel (Utilise the self assign roles in #role-management)
        </p>
        <p>
          For any questions or queries contact us at{" "}
          <a href="mailto:contact@qutroboticsclub.com">contact@qutroboticsclub.com</a>
            {" "}or go to our <a href="/contact">contact page</a>.
        </p>

        <hr/>

        <section id="drc-resources">
            <h2>DRC Resources</h2>
            <a href="https://www.mathworks.com/">
                <img id="mathworks-logo" className="img" src={Mathworks} alt="MathWorks logo"/>
            </a>
            <p className="justify" style={{ marginBottom: "1%" }}>
                MathWorks, makers of MATLAB and Simulink, is offering participating teams complimentary software, tutorials, and videos. They are also
                offering a prize to participants for the best implementation of MATLAB and other MathWorks software. Find out more about how technical
                computing and Model-Based Design can help you compete:
            </p>
            <a className="pretty-link" href="/drc/mathworks-resources">MathWorks Resources</a>
            <br />
            <a className="pretty-link" href="/drc/matlab-adoption-prize-rules">MathWorks Prize Rules</a>
            <br />
            <a className="pretty-link" href="/drc/mathworks-resources">General Resources</a>
        </section>

      <hr/>
      <h2>2023 Club Sponsors</h2>
      <Stack
        direction={isMobile ? "column" : "row"}
        alignItems="center"
        justifyContent="center"
      >
        <img src={Boeing} alt="Boeing" height={"200vh"} width={isXlDesktop ? "400vh" : "200vh"} style={{ paddingRight: "4%" }}/>
        <img src={QCR} alt="QCR" height={"200vh"} width={isXlDesktop ? "400vh" : "200vh"} style={{ paddingRight: "4%" }} />
        <img src={WatkinsSteel} alt="Watkins-Steel" height={"100vh"} width={isXlDesktop ? "400vh" : "200vh"} style={{ paddingRight: "4%" }}/>
      </Stack>
    </Wrapper>
  );
};

export default TwentyTwentyThree;
