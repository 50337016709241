import React from "react";
import Headshot from "../components/headshot";
import {useState, useEffect} from "react";
import {getExecs} from "../db/data.js";

const Team = () => {
    const [execs, setExecs] = useState([])
    useEffect(() => {
        getExecs()
            .then(execs => {
                setExecs(execs)
            })
    }, [])

  return (
    <div className="team-page">
      <h1>Our Team</h1>
      <div className="headshot-container">
        {execs.map((exec) => (
            <Headshot key={exec.id} exec={exec} />
        ))}
      </div>
    </div>
  );
};

export default Team;
