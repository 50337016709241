import React, {useEffect, useState} from "react";
import '../css/events.css'
import {getEvents} from "../db/data.js"
import {DateTime} from "luxon";


const Events = () => {
    const [events, setEvents] = useState([])
    useEffect(() => {
        getEvents()
            .then(events => {
                setEvents(events)
            })
    }, [])


    return (
        <div className="events-page page-container">
            <h1>Events</h1>
            {events.map((year, id) => {
                return (
                    <div key={id} className="event-block" id={year.year + "-event-block"}>
                        <h2>{year.year}</h2>
                        {year.events.map((event, id) => {
                            const date = DateTime.fromFormat(event.date, 'dd/MM/y').toFormat('d/M')
                            
                            let endDate = ""
                            if(event.endDate !== undefined){
                                endDate = DateTime.fromFormat(event.endDate, 'dd/MM/y').toFormat('d/M')
                                endDate = " - " + endDate
                            }

                            return (
                                <div key={id} className='event' id={event.firestoreId}>
                                    <div>
                                        <span className="event-date"><b>{date + endDate}</b></span>
                                        <p className='event-title'>{event.title}</p>
                                    </div>
                                    <div>
                                        {event.links && event.links.map((link, id) => {
                                            return (
                                                <>
                                                    <a key={id} href={link.url} className='pretty-link' target='__blank'>
                                                        {link.title}
                                                    </a>
                                                    <br/>
                                                </>
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                )
            }) }
        </div>
    )
}

export default Events;