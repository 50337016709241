// firebase
import {getFirestore, getDocs, collection} from "firebase/firestore";
import {initializeApp} from 'firebase/app'

// Datetime
import {DateTime} from 'luxon'

/**
 * firebase config information retrieved from firebase app settings. No secrets here.
 * @type {{storageBucket: string, apiKey: string, messagingSenderId: string, appId: string, projectId: string, measurementId: string, databaseURL: string, authDomain: string}}
 */
const firebaseConfig = {
    apiKey: "AIzaSyDROsAiIyc4luyVu_Hw12csp7jw7uP-eTQ",
    authDomain: "qutrc-website-551dc.firebaseapp.com",
    databaseURL: "https://qutrc-website-551dc-default-rtdb.firebaseio.com",
    projectId: "qutrc-website-551dc",
    storageBucket: "qutrc-website-551dc.appspot.com",
    messagingSenderId: "768015268726",
    appId: "1:768015268726:web:9304fe6de4cb796791d857",
    measurementId: "G-7HX4QFDR55"
};

// initialize firebase app object and db connection
const app = initializeApp(firebaseConfig)
const db = getFirestore(app)

/**
 * Get a list of all QUTRC events from the firestore database
 * @returns {Promise<*[]>} A promise that resolves to a list of event objects
 */
function getEvents() {
    let events_data = []
    return getDocs(collection(db, 'events'))
        .then(docs => {
            docs.forEach(doc => {
                // get event object and add firestoreId
                const event = doc.data()
                event.firestoreId = doc.id;

                // add default date if missing
                if (!event.date) {
                    event.date = "01/01/1970"
                }

                // Sort events into year lists
                const date = DateTime.fromFormat(event.date, 'dd/MM/y')
                const yearObj = events_data.find(x => x.year === date.year)
                if (yearObj) {
                    yearObj.events.push(event)
                } else {
                    events_data.push({
                        year: date.year,
                        events: [event]
                    })
                }
            })

            // sort year lists in descending order
            events_data.sort((a, b) => {
                const a_int = Number(a.year)
                const b_int = Number(b.year)
                if (a_int > b_int) return 1
                else if (a_int < b_int) return -1
                else return 0
            }).reverse()

            // sort events within year lists by dates in descending order
            events_data.forEach(year => {
                year.events.sort((a, b) => {
                    const aDate = DateTime.fromFormat(a.date, 'dd/MM/y').toMillis()
                    const bDate = DateTime.fromFormat(b.date, 'dd/MM/y').toMillis()
                    if (aDate > bDate) return 1
                    else if (aDate < bDate) return -1
                    else return 0
                }).reverse()
            })

            return events_data
        })
}

/**
 * Get a list of the executive team from the firestore database
 * @returns {Promise<*[]>} A promise that resolves to a list of exec objects
 */
function getExecs() {
    return getDocs(collection(db, 'execs'))
        .then(docs => {
            const execs = []
            docs.forEach(doc => {
                // get exec object and add firestoreId
                const exec = doc.data()
                exec.firestoreId = doc.id
                execs.push(exec)
            })

            // sort execs by id
            execs.sort((a, b) => {
                if (a.id > b.id) return 1
                else if (a.id < b.id) return -1
                else return 0
            })

            return execs
        })
}

/**
 * Get list of website developers from firestore database
 * @returns {Promise<*[]>} A promise that resolves to a list of developer objects
 */
function getDevs() {
    return getDocs(collection(db, 'developers'))
        .then(docs => {
            const devs = []
            docs.forEach(doc => {
                // get dev object and add firestoreId
                const dev = doc.data()
                dev.firestoreId = doc.id
                devs.push(dev)
            })

            // sort devs by id
            devs.sort((a, b) => {
                if (a.id > b.id) return 1
                else if (a.id < b.id) return -1
                else return 0
            })

            return devs
        })
}

/**
 * Get list of projects from firestore database.
 * @returns {Promise<*[]>} A promise that resolves to a list of project objects.
 */
function getProjects() {
    return getDocs(collection(db, 'projects'))
        .then(docs => {
            const projects = []
            docs.forEach(doc => {
                // get project object and add firestoreId
                const project = doc.data()
                project.firestoreId = doc.id
                projects.push(project)
            })

            // sort projects by id
            projects.sort((a, b) => {
                if (a.year < b.year) return 1
                else if (a.year > b.year) return -1
                else return 0
            })

            return projects
        })
}

export {getEvents, getProjects, getExecs, getDevs};