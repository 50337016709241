import React from "react";
import { Wrapper } from "../../../components/Wrapper.styles";
import Sumobots from "../../../images/projects/Sumobots.png";
import Sumobots_Showcase from "../../../images/resource_links/Workbots_Showcase.jpg";

const WorkBots = () => {
  return (
    <Wrapper>
      <h1>WorkBot</h1>
      <img src={Sumobots} alt="Sumobots" />
      <p>
        In 2017 Sumobots were created and led to the creation of the WorkBots by
        Filippo Capurso and Georgina Hine in 2017. They both made up a series of
        workshops where in 2016 the goal was to design, build and program a sumo
        robot with the Workbots in 2017 being teaching tools for the design and
        building process and programmed by individuals to follow the EGB220 line
        following map. (All resources will be put onto the GitHub in time))
      </p>
      <p>
        In 2017 Sumobots were created and led to the creation of the WorkBots by
        Filippo Capurso and Georgina Hine in 2017. They both made up a series of
        workshops where in 2016 the goal was to design, build and program a sumo
        robot with the Workbots in 2017 being teaching tools for the design and
        building process and programmed by individuals to follow the EGB220 line
        following map. (All resources will be put onto the GitHub in time))
      </p>
      <p>
        A line-follower platform developed for workshops to teach CAD, PCB
        design, and C.
      </p>
      <p>Resources:</p>
      <ul>
        <li>
          <a
            href="https://drive.google.com/drive/folders/1bcvwQT2wblyzTmMubjK_HYff-eNJkpfj"
            target="_blank"
            rel="noreferrer"
          >
            Schematics
          </a>
        </li>
        <li>
          <a
            href="https://drive.google.com/file/d/1bPrjJDF9cfmBjDr4tIrVO9hnk1-8oJPO/view?usp=sharing"
            target="_blank"
            rel="noreferrer"
          >
            Eagle Files
          </a>
        </li>
        <li>
          <a
            href="https://drive.google.com/drive/folders/10tqQynKzpBQ8h_fu_sFXVA3RyUT6qfE0?usp=sharing"
            target="_blank"
            rel="noreferrer"
          >
            Program Files
          </a>
        </li>
        <li>
          <a
            href="https://drive.google.com/drive/folders/1Rb3qovDo0_0ht2H1deUsaFqrN7U2f8Cx?usp=sharing"
            target="_blank"
            rel="noreferrer"
          >
            WorkBots Laser Cut Designs
          </a>
        </li>
      </ul>
      <img src={Sumobots_Showcase} alt="Sumobots_showcase" />
    </Wrapper>
  );
};

export default WorkBots;
