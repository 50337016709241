import React from "react";
import DRC from "../../images/drc-header-1.png";
import styled from "styled-components";
import ResourceLink from "../../././components/resource_link";

import CARE_Architecture from "../../db/CARE_Workshop_Series_-_System_Architecture.pptx";
import CARE_Parts from "../../db/CARE_Workshop_Series_-_Parts_Selection.pptx";
import CARE_Testing from "../../db/CARE_Workshop_Series_-_System_Integration_and_Testing.pptx";


const DRCSection = styled.div`
  border-top: black solid 1px;
  /* border-bottom: black solid 1px; */
  margin: 0 15%;

  a {
    color: black;
    z-index: 2;
    padding-bottom: 5%;
  }

  img {
    max-width: 100%;
    height: auto;
  }
`;

const WorkshopSection = styled.div`
  padding-bottom: 5%;
  margin: 5% 15% 0 15%;

  ul {
    list-style: none;
    padding: 0;
  }

  a {
    color: black;
  }
`;

const Resources = () => {
  return (
    <div>
      <h1>Resources</h1>
      <DRCSection>
        <h2>DRC Resources</h2>
        <img src={DRC} alt="DRC-Header" />
        <h4>
          <ResourceLink href="drc/teams-portal" label="Read More">
            Competing Teams' Portal and Resources
          </ResourceLink>
        </h4>
        <h4>
          <ResourceLink href="drc/autonomous-vehicle-guide" label="Read More">
            How to make an autonomous vehicle - the 2016 Droid Racing Challenge
          </ResourceLink>
        </h4>
        <h4>
          <ResourceLink href="drc/mathworks-resources" label="Read More">
            Mathworks Resources
          </ResourceLink>
        </h4>
      </DRCSection>
      <WorkshopSection>
        <h2>Workshop Content</h2>


        <h2>2023</h2>
        <ul>
          <li>
            <ResourceLink
              href="https://goofy-slicer-49c.notion.site/ROBOTS101-Wiki-94efa6e21a2141c29607c81e5a44d13d"
              label="Notion Wiki"
            >
              Robots101
            </ResourceLink>
          </li>
        </ul>


        <h2>2022</h2>
        <ul>
          <li>
            <ResourceLink
              href={CARE_Architecture}
              label="Download Slides"
              download="CARE_Workshop_Series_-_System_Architecture.pptx"
              target="_blank"
              rel="noopener noreferrer"
            >
              CARE Workshop Series - System Architecture
            </ResourceLink>
          </li>
          <li>
            <ResourceLink
              href={CARE_Parts}
              label="Download Slides"
            >
              CARE Workshop Series - Parts Selection
            </ResourceLink>
          </li>
          <li>
            <ResourceLink
              href={CARE_Testing}
              label="Download Slides"
            >
              CARE Workshop Series - System Integration and Testing
            </ResourceLink>
          </li>
        </ul>


        <h2>2021</h2>
        <ul>
          <li>
            <ResourceLink
              href="https://docs.google.com/presentation/d/1aml2Lu8vB4PANmGcl7h4pVawpkH1YNFnJrdI2cgLcmk/edit?usp=sharing"
              label="Read More"
            >
              Github Workshop
            </ResourceLink>
          </li>
          <li>
            <ResourceLink
              href="https://github.com/Haard-Shah/ROS_Workshops"
              label="Read More"
            >
              ROS Workshop
            </ResourceLink>
          </li>
        </ul>


        <h2>2018</h2>
        <ul>
          <li>
            <ResourceLink
              href="/resources/workshops/robot-arm"
              label="Read More"
            >
              Robot Arm Workshop
            </ResourceLink>
          </li>
        </ul>


        <h2>2017</h2>
        <ul>
          <li>
            <ResourceLink
              href="/resources/workshops/workbot"
              label="Read More"
            >
              WorkBot
            </ResourceLink>
          </li>
        </ul>


        <h2>2016</h2>
        <ul>
          <li>
            <ResourceLink
              href="/resources/workshops/suppliers"
              label="Read More"
            >
              Suppliers
            </ResourceLink>
          </li>
        </ul>


        <h2>2015</h2>
        <ul>
          <li>
            <ResourceLink
              href="resources/workshops/robotic-systems"
              label="Read More"
            >
              Robotic Systems
            </ResourceLink>
          </li>
          <li>
            <ResourceLink
              href="resources/workshops/soldering"
              label="Read More"
            >
              Soldering
            </ResourceLink>
          </li>
          <li>
            <ResourceLink
              href="resources/workshops/3d-modelling-with-cad"
              label="Read More"
            >
              Modelling and Mechanical Design using CAD
            </ResourceLink>
          </li>
          <li>
            <ResourceLink
              href="resources/workshops/robot-power-systems"
              label="Read More"
            >
              Robot Power Systems
            </ResourceLink>
          </li>
          <li>
            <ResourceLink
              href="resources/workshops/programming-microcontrollers-in-c"
              label="Read More"
            >
              Programming Microcontrollers in C
            </ResourceLink>
          </li>
        </ul>
      </WorkshopSection>
    </div>
  );
};

export default Resources;
