import React from "react";
import { Wrapper } from "../../components/Wrapper.styles";
import ReactPlayer from "react-player";

const TeamsPortal = () => {
  return (
    <Wrapper>
      <h1>Competing Teams’ Portal and Resources</h1>
      <div>
        <a href="/drc/faqs" target="_blank" rel="noreferrer">FAQS{" "}</a>
        |
        <a href="/drc/rules" target="_blank" rel="noreferrer">{" "}Rules{" "}</a>
        |
        <a href="/drc/autonomous-vehicle-guide" target="_blank" rel="noreferrer">{" "}Technical Advice{" "}</a>
      </div>

      <p style={{ marginBottom: "1%" }}>
        The MathWorks are one of the sponsors for this year’s Droid Racing
        Challenge(DRC) and are making available trial licenses of MATLAB(and
        around 80 toolboxes) to ALL teams/students registered in the DRC. This
        includes access to some of the most recent toolboxes specific to the
        challenge, such as the:
      </p>
      <ul>
        <li><a href="https://au.mathworks.com/products/automated-driving.html" target="_blank" rel="noreferrer">Automated Driving System Toolbox</a></li>
        <li><a href="https://au.mathworks.com/products/computer-vision.html" target="_blank" rel="noreferrer">Computer Vision System Toolbox</a></li>
        <li><a href="https://au.mathworks.com/products/robotics.html" target="_blank" rel="noreferrer">Robotics System Toolbox</a></li>
      </ul>

      <p style={{ marginBottom: "1%" }}>You can request a trial license and also access other resources, here:</p>
      <a href="https://au.mathworks.com/academia/student-competitions/droid-racing.html" target="_blank" rel="noreferrer">https://au.mathworks.com/academia/student-competitions/droid-racing.html</a>
      <p style={{ marginBottom: "1%" }}>
        To give you an idea of the problems encountered by all the teams last
        year, we created some test videos of the most critical track section
        (the start). We also recorded some videos of another section of the
        track, though because of some technical oversights these were not
        rendered correctly. We’ll be posting more videos in a couple of weeks:
        let us know if you have any particular requests by sending an email to
        <a href="mailto:contact@qutroboticsclub.com">contact@qutroboticsclub.com</a>.
        <br/><br/>
        Following are three videos of the initial section of the track. Two
        recorded at 11:00 AM with different light conditions (sunny and cloudy)
        and one recorded at 1:30 PM. We have recorded a total of 7 videos of
        this section (even some going in the opposite direction). You can find
        all the videos here
        <a href="https://www.youtube.com/playlist?list=PLGLc1hZ9SMLx0ijmWpYBxCxPw0pndwuSl" target="_blank" rel="noreferrer">{" "}Youtube: Droid Racing Challenge 2017</a>
      </p>

      <h2>11 AM - Sunny</h2>
      <ReactPlayer
        url="https://www.youtube.com/watch?v=4O6qtMuKbns&list=PLGLc1hZ9SMLx0ijmWpYBxCxPw0pndwuSl&ab_channel=QUTRoboticsClub"
        width="100%"
      />
      
      <h2>11 AM - Cloudy</h2>
      <ReactPlayer
        url="https://www.youtube.com/watch?v=4es2jDi7WWg&ab_channel=QUTRoboticsClub"
        width="100%"
      />
      
      <h2>1:30 PM – 1st of 2 Runs</h2>
      <ReactPlayer
        url="https://www.youtube.com/watch?v=3rHPLKQV30o&ab_channel=QUTRoboticsClub"
        width="100%"
      />
    </Wrapper>
  );
};

export default TeamsPortal;
