import React from "react";
import styled from "styled-components";

const race_schedule= [
  {
    "Race #": "1",
    "Team 1": "QUT1",
    "Team 2": "UQ"
  },
  {
    "Race #": "2",
    "Team 1": "UQ",
    "Team 2": "QUT2"
  },
  {
    "Race #": "3",
    "Team 1": "QUT2",
    "Team 2": "Griffith"
  },
  {
    "Race #": "4",
    "Team 1": "Griffith",
    "Team 2": "QUT3"
  },
  {
    "Race #": "5",
    "Team 1": "QUT3",
    "Team 2": "UNSW"
  },
  {
    "Race #": "6",
    "Team 1": "QUT1",
    "Team 2": "QUT2"
  },
  {
    "Race #": "7",
    "Team 1": "UQ",
    "Team 2": "Griffith"
  },
  {
    "Race #": "8",
    "Team 1": "QUT2",
    "Team 2": "QUT3"
  },
  {
    "Race #": "9",
    "Team 1": "Griffith",
    "Team 2": "UNSW"
  },
  {
    "Race #": "10",
    "Team 1": "QUT1",
    "Team 2": "Griffith"
  },
  {
    "Race #": "11",
    "Team 1": "UQ",
    "Team 2": "QUT3"
  },
  {
    "Race #": "12",
    "Team 1": "QUT2",
    "Team 2": "UNSW"
  },
  {
    "Race #": "13",
    "Team 1": "QUT1",
    "Team 2": "QUT3"
  },
  {
    "Race #": "14",
    "Team 1": "UQ",
    "Team 2": "UNSW"
  },
  {
    "Race #": "15",
    "Team 1": "QUT1",
    "Team 2": "UNSW"
  },
  {
    "Race #": "Semi 1",
    "Team 1": "1st place",
    "Team 2": "4th place"
  },
  {
    "Race #": "Semi 2",
    "Team 1": "2nd place",
    "Team 2": "3rd place"
  },
  {
    "Race #": "Grand",
    "Team 1": "Semi 1 winner",
    "Team 2": "Semi 2 winner"
  }
]

const Wrapper = styled.div`
  border-top: black solid 1px;
  margin: 0 15%;
  padding-bottom: 10%;

  a {
    color: blue;
    z-index: 2;
    padding-bottom: 5%;
    text-decoration: none;

    &:hover {
      color: black;
    }
  }

  p {
    white-space: pre-wrap;
    margin-bottom: 5%;
  }

  li {
    list-style: circle;
    list-style-position: inside;
    text-align: left;
    margin-bottom: 1%;
  }

  @media (max-width: 900px) {
    padding-bottom: 10%;
    li {
      margin-bottom: 5%;
    }
  }
`;

const CompetitionFormat = () => {
  return (
    <Wrapper>
      <h1 style={{ marginBottom: "4%" }}>Competition Format</h1>
      
        <h3 style={{ color: "red"}}>This now out of date but can be used as a basis of how the competition could run.</h3>
        <h3 style={{ color: "red", marginBottom: "4%" }}>Updated: 23/06/2016</h3>
        
          <p className="left">Space will be provided on both days for teams to store droids, parts and other items throughout the day. Teams can work on their droids in this “workshop” space. Basic tools, battery chargers, tables and chairs will be provided here. This location will be locked overnight. The “workshop” room will be in O-block. Gazebos will be set up near the track on competition day as well.</p>
          
        <h3>June 28th: Practice and Testing</h3>
          
          <p className="left" style={{ marginBottom: "3%" }}>Every team will have at least one hour of allocated track time to test their droid on the race track on the 28th of June. If a team is willing to allow other teams to also use the track during their allocated time that is up to them, but the team who has that hour has final say. Testing after 3pm is allowed but there will be no allocated times after this.</p>
          <p className="left" style={{ marginBottom: "3%" }}>Teams must be present at the Kidney Lawn, QUT Gardens Point Campus 30 minutes before their allocated track time, for an orientation to the workshop and track locations and an overview of the event.</p>
          <p>
            9am QUT 1 <br/>
            10am – UQ <br/>
            11am – QUT 2 <br/>
            12am – Griffith <br/>
            1pm – QUT 3 <br/>
            2pm – UNSW <br/>
            3pm onwards – open to all teams <br/>
          </p>
          
        <h3>June 29th: Competition Day</h3>
          <p className="left">Teams will meet at QUT in the workshop room by 9am ready for a briefing at 9:15. Races will commence at 10am. A round-robin format will be used, with each droid racing against every other droid in pairs, then the top four placed droids will race semi finals then a grand final. The day should finish before 3pm.</p>
          <p className="left" style={{ marginBottom: "2%" }}>The current plan for races is as follows:</p>

      <table style={{marginLeft:'auto',marginRight:'auto'}} border="0" width="25%" cellspacing="5%" cellpadding="2%" className="center">
      <colgroup>
        <col span="3" width="64"/>
      </colgroup>
      <tbody>
      <tr>
          <td width="64" height="21"><b>Race #</b></td>
          <td width="64"><b>Team 1</b></td>
          <td width="64"><b>Team 2</b></td>
        </tr>
      {
        race_schedule.map((row) => {
          return <tr>
          <td width="64" height="21">{row["Race #"]}</td>
          <td width="64">{row["Team 1"]}</td>
          <td width="64">{row["Team 2"]}</td>
          </tr>
      })}
      </tbody>
      </table>

    </Wrapper>
  );
};

export default CompetitionFormat;


