import React from 'react';
import styled from 'styled-components';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Link } from 'react-router-dom';

const DialogWrapper = styled.div`
    padding: 0 2vh 5vh 2vh;
    background-color: #112236;
    color: #fff;
    text-align: center;

    img{
        object-fit: contain;
    }
`

// CSS styling grabbed from https://getcssscan.com/css-buttons-examples
const Button = styled.button`
  background-color: #FFFFFF;
  border: 1px solid #222222;
  border-radius: 8px;
  box-sizing: border-box;
  color: #222222;
  cursor: pointer;
  display: inline-block;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin: 0;
  outline: none;
  padding: 13px 23px;
  position: relative;
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  transition: box-shadow .2s,-ms-transform .1s,-webkit-transform .1s,transform .1s;
  user-select: none;
  -webkit-user-select: none;
  width: auto;

&:focus-visible {
  box-shadow: #222222 0 0 0 2px, rgba(255, 255, 255, 0.8) 0 0 0 4px;
  transition: box-shadow .2s;
}

&:active {
  background-color: #F7F7F7;
  border-color: #000000;
  transform: scale(.96);
}

&:disabled {
  border-color: #DDDDDD;
  color: #DDDDDD;
  cursor: not-allowed;
  opacity: 1;
}
`

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const PopupDialog = (props) => {
    const { onClose, open, project } = props

    const handleClose = () => {
        onClose();
    }

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            BackdropProps={{ style: { backgroundColor: "transparent" } }}
            PaperProps={{
                style: { borderRadius: 20 }
            }}
        >
            <DialogWrapper>
                <DialogTitle className='dialog-title'
                    style={{ textAlign: 'center', fontWeight: 'bold' }}
                >
                    {project.title}
                </DialogTitle>
                <img src={require(`../images/projects/${project.imagePath}`).default}
                    alt={`${project.title}`}
                    height={200}
                    width={300}
                />
                <p>{project.description}</p>
                <Link to={`/projects/${project.slug}`} >
                    <Button>Read More</Button>
                </Link>
            </DialogWrapper>
        </Dialog>
    )
};

export default PopupDialog;
