import React from "react";
import styled from "styled-components";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import DRC from "..//../../images/drc-header-1.png";
import QUTRCLogo from "..//../../images/qutrc_smalllogo.png";
import "../../../css/drc.css";
import DRCResult from "../../../images/drc/DRC_2019_Results.png";
import Mathworks from "../../../images/sponsors/mathworks.svg";
import Boeing from "../../../images/sponsors/boeing.svg";
import QCR from "../../../images/sponsors/qcr.svg";

const Wrapper = styled.div`
  border-top: black solid 1px;
  padding-bottom: 10%;
  a {
    color: blue;
    z-index: 2;
    padding-bottom: 1%;
    text-decoration: none;

    &:hover {
      color: black;
    }
  }

  p {
    white-space: pre-wrap;
    margin-bottom: 3%;
    text-align: left;
  }

  li {
    list-style: circle;
    list-style-position: inside;
    text-align: left;
  }

  img {
    max-width: 100%;
    max-height: 60vh;
  }

  @media (max-width: 900px) {
    padding-bottom: 15%;
  }
`;

/* Can't use numbers e.g. 2021 */
const TwentyNineteen = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isXlDesktop = useMediaQuery(theme.breakpoints.only("xl"));
  return (
    <Wrapper className="drc-page page-container">
      <h1>Droid Racing Challenge 2019</h1>
      <div>
        <img src={DRC} alt="DRC-Header" />
      </div>

      <Stack direction="row" alignItems="center" justifyContent="center">
        <div>
          <img src={QUTRCLogo} alt="DRC-Header" height={"200px"} />
        </div>
        <div>
          <ul>
            <li><a className="pretty-link" href="https://forms.gle/TqNiK3CZaHZ67nRs5">Registration</a></li>
            <li><a className="pretty-link" href="https://qutrobotics.wordpress.com/droid-racing-challenge/drc-key-dates/">Key Dates</a></li>
            <li><a className="pretty-link" href="/drc/rules">Rules</a></li>
            <li><a className="pretty-link" href="/sponsors">Sponsors</a></li>
            <li><a className="pretty-link" href="map">Directional Map</a></li>
          </ul>
        </div>
      </Stack>
      <hr/>

      <h2>The Droid Racing Challenge is complete for 2019.</h2>
      <div>
        <p>
          DRC 2019 has come to a close and it was a great competition. Thankyou
          to all teams who participated and to our fantastic sponsors at Boeing
          and Mathworks, as well as from the QUT sef engagement team. Also a
          massive shout out to our volunteers, couldn’t have done it without
          you.
          <br/><br/>
          A full event wrap-up and photos from the event will be completed soon
          so keep your ear to the ground. If you have enquiries about the event
          or would like to register your interest in participating or sponsoring
          DRC 2020, please send an email to qutroboticsclub@gmail.com
        </p>
      </div>

      <img src={DRCResult} alt="DRC 2019 Results" />

      <hr/>

      <section id="drc-resources">
            <h2>DRC Resources</h2>
            <a href="https://www.mathworks.com/">
                <img id="mathworks-logo" className="img" src={Mathworks} alt="MathWorks logo"/>
            </a>
            <p className="justify" style={{ marginBottom: "1%" }}>
                MathWorks, makers of MATLAB and Simulink, is offering participating teams complimentary software, tutorials, and videos. They are also
                offering a prize to participants for the best implementation of MATLAB and other MathWorks software. Find out more about how technical
                computing and Model-Based Design can help you compete:
            </p>
            <a className="pretty-link" href="/drc/mathworks-resources">MathWorks Resources</a>
            <br />
            <a className="pretty-link" href="/drc/matlab-adoption-prize-rules">MathWorks Prize Rules</a>
            <br />
            <a className="pretty-link" href="/drc/mathworks-resources">General Resources</a>
        </section>

      <hr/>

      <h3>2019 Club Sponsors</h3>
      <Stack direction={isMobile ? "column" : "row"} alignItems="center"justifyContent="center">
        <img src={Boeing} alt="Boeing" height={"200vh"} width={isXlDesktop ? "400vh" : "200vh"} style={{ paddingRight: "4%" }}/>
        <img src={QCR} alt="QCR" height={"200vh"} width={isXlDesktop ? "400vh" : "200vh"} style={{ paddingRight: "4%" }}/>
        <img src={Mathworks} alt="MathWorks" height={"200vh"} width={isXlDesktop ? "400vh" : "200vh"}/>
      </Stack>
    </Wrapper>
  );
};

export default TwentyNineteen;
