import React from 'react'
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import styled from 'styled-components';

const Wrapper = styled.div`
    margin-bottom: -3%;
    font-family: 'Montserrat', sans-serif;

    .MuiLink-root{
        color: #112236;
    }

    .MuiBreadcrumbs-root{
        color: #112236;
    }
`

const ProjectBreadcrumbs = ({ projectName }) => {
    return (
        <Wrapper>
            <Breadcrumbs aria-label="breadcrumb">
                <Link
                    underline="hover"
                    href="/"
                >
                    Home
                </Link>
                <Link
                    underline="hover"
                    href="/projects"
                >
                    Projects
                </Link>
                <Typography style={{ marginTop: '3%' }}>{projectName}</Typography>
            </Breadcrumbs>
        </Wrapper>
    )
}

export default ProjectBreadcrumbs