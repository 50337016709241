import React from "react";
import styled from "styled-components";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import DRC from "..//../../images/drc-header-1.png";
import QUTRCLogo from "..//../../images/qutrc_smalllogo.png";
import "../../../css/drc.css";
import DRCResult from "../../../images/drc/DRC_2022_Results.png";
import Mathworks from "../../../images/sponsors/mathworks.svg";
import Boeing from "../../../images/sponsors/boeing.svg";
import QCR from "../../../images/sponsors/qcr.svg";
import WatkinsSteel from "../../../images/sponsors/Watkins_Steel.png";

const Wrapper = styled.div`
  border-top: black solid 1px;
  padding-bottom: 10%;
  a {
    color: blue;
    z-index: 2;
    padding-bottom: 1%;
    text-decoration: none;

    &:hover {
      color: black;
    }
  }

  p {
    white-space: pre-wrap;
    margin-bottom: 3%;
    text-align: left;
  }

  li {
    list-style: circle;
    list-style-position: inside;
    text-align: left;
  }

  img {
    max-width: 100%;
    max-height: 60vh;
  }

  @media (max-width: 900px) {
    padding-bottom: 15%;
  }
`;

/* Can't use numbers e.g. 2021 */
const TwentyTwentytwo = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isXlDesktop = useMediaQuery(theme.breakpoints.only("xl"));
  return (
    <Wrapper className="drc-page page-container">
      <h1>Droid Racing Challenge 2022</h1>
      <div>
        <img src={DRC} alt="DRC-Header" />
      </div>

      <Stack direction="row" alignItems="center" justifyContent="center">
        <div>
          <img src={QUTRCLogo} alt="DRC-Header" height={"200px"} />
        </div>
        <div>
          <ul>
            <li><a className="pretty-link" href="https://forms.gle/7Uq4faMzZTYHGcT47">Expression of Interest</a></li>
            <li><a className="pretty-link" href="https://forms.gle/QAK1ZyHkRJ5gi5je8">Registration</a></li>
            <li><a className="pretty-link" href="https://qutrobotics.wordpress.com/droid-racing-challenge/drc-key-dates/">Key Dates</a></li>
            <li><a className="pretty-link" href="/drc/rules">Rules</a></li>
            <li><a className="pretty-link" href="/sponsors">Sponsors</a></li>
            <li><a className="pretty-link" href="/drc/map">Directional Map</a></li>
          </ul>
        </div>
      </Stack>
      <hr/>

        <p>
          Congratulations to the 13 teams from QLD and NSW that were able to
          compete on the 15th July at DRC 2022, it was fantastic to see so many
          teams again and interstate travellers. We had some exciting races, a
          wonderful presentation by Dr Stephen Hausler from the QUT Centre for
          Robotics on “Autonomous Navigation – from Pixels to Pose” and another
          successful stream to an international audience. This year saw 11
          university teams and 2 high school teams battle it out for the prizes
          and we hope to see even more in the future.
        </p>

        <img src={DRCResult} alt="DRC 2022 Results" />

        <p>Link to photos:{" "}
          <a href="https://www.facebook.com/media/set/?vanity=shaunmoonphotography&set=a.570073848180206">2022 DRC QLD Event</a>
        </p>
        <p>Stream available until 18/19th July on{" "}<a href="https://www.twitch.tv/qutrc">Twitch</a></p>
        
        <hr/>

        <p>
          Beginning in 2016, QUT Robotics Club has hosted the Droid Racing
          Challenge – a friendly competition where universities from around
          Australia and the world are invited to bring their automated bots to
          traverse an obstacle course!
          <br/><br/>
          This year we are back in Brisbane better then ever with a bigger
          competition! This year we will be introducing team brackets where High
          School Students, University Students, Higher Degree Research Students
          and Education Facility Staff may all compete! Please see updated rules
          at the top of the page for more information!
          <br/><br/>
          This years competition will be run from 14/07/22 to 15/07/22 with the
          first day dedicated to final testing and the second day to our official
          competition. Please check key dates for more dates.
        </p>
        
        <p style={{ marginBottom: "1%" }}>Link to other photos:</p>
        <ul>
          <li><a href="https://drive.google.com/drive/folders/1zWRN-TpXOqCepwuu9eCkagXH7nGucFfk?usp=sharing">–2021 DRC QLD Event</a></li>
          <li><a href="https://drive.google.com/drive/folders/1OyfL4ijk3GzrkEyLqLy8aR5jO_MyjdNB?usp=sharing">–2019 DRC QLD Event</a></li>
        </ul>
        
        
        <p style={{ marginTop: "3%" }}>Best of luck to all teams!</p>
        <p>
          Make sure to follow our{" "}
          <a href="http://www.facebook.com/qutroboticsclub">Facebook</a> for the
          latest information. Alternatively join our{" "}
          <a href="https://discord.gg/cQa8VzVgT4">Discord</a> in the DRC Specific
          Channel (Utilise the self assign roles in #role-management)
        </p>
        <p>
          For any questions or queries contact us at{" "}
          <a href="mailto:contact@qutroboticsclub.com">contact@qutroboticsclub.com</a>
            {" "}or go to our <a href="/contact">contact page</a>.
        </p>

        <hr/>

      <section id="drc-resources">
          <h2>DRC Resources</h2>
          <a href="https://www.mathworks.com/">
              <img id="mathworks-logo" className="img" src={Mathworks} alt="MathWorks logo"/>
          </a>
          <p className="justify" style={{ marginBottom: "1%" }}>
              MathWorks, makers of MATLAB and Simulink, is offering participating teams complimentary software, tutorials, and videos. They are also
              offering a prize to participants for the best implementation of MATLAB and other MathWorks software. Find out more about how technical
              computing and Model-Based Design can help you compete:
          </p>
          <a className="pretty-link" href="/drc/mathworks-resources">MathWorks Resources</a>
          <br />
          <a className="pretty-link" href="/drc/matlab-adoption-prize-rules">MathWorks Prize Rules</a>
          <br />
          <a className="pretty-link" href="/drc/mathworks-resources">General Resources</a>
      </section>


      <hr/>
      <h2>2022 Club Sponsors</h2>
      <Stack
        direction={isMobile ? "column" : "row"}
        alignItems="center"
        justifyContent="center"
      >
        <img src={Boeing} alt="Boeing" height={"200vh"} width={isXlDesktop ? "400vh" : "200vh"} style={{ paddingRight: "4%" }}/>
        <img src={QCR} alt="QCR" height={"200vh"} width={isXlDesktop ? "400vh" : "200vh"} style={{ paddingRight: "4%" }}/>
        <img src={WatkinsSteel} alt="Watkins-Steel" height={"100vh"} width={isXlDesktop ? "400vh" : "200vh"} style={{ paddingRight: "4%" }}/>
        <img src={Mathworks} alt="MathWorks" height={"200vh"} width={isXlDesktop ? "400vh" : "200vh"}/>
      </Stack>
    </Wrapper>
  );
};

export default TwentyTwentytwo;
