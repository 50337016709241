import React from "react";
import { Wrapper } from "../../../components/Wrapper.styles";

const RoboticsSystems = () => {
  return (
    <Wrapper>
      <h1>Robotics Systems</h1>
      <p>
        These workshops are about how to go about the overall process of
        designing a robot from the ground up. These slides are from workshops
        we’ve run on this topic over the last couple of years.
      </p>
      <div>
        <a
          href="https://qutrobotics.files.wordpress.com/2015/04/robotic.pdf"
          target="_blank"
          rel="noreferrer"
        >
          Robotic Systems{" "}
        </a>
        |
        <a
          href="https://qutrobotics.files.wordpress.com/2015/04/systems-design.pdf"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          Systems Design{" "}
        </a>
        |
        <a
          href="https://qutrobotics.files.wordpress.com/2015/04/workshop1.pdf"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          Workshop1{" "}
        </a>
      </div>
      <p>Thanks to Kyran Findlater and Karl Von Richter.</p>
    </Wrapper>
  );
};

export default RoboticsSystems;
