import React from "react";
import { Wrapper } from "../../../components/Wrapper.styles";

const RobotPowerSystems = () => {
  return (
    <Wrapper>
      <h1>Robot Power Systems</h1>
      <p>
        This workshop is all about how to power your robots and the
        considerations that need to be made when designing and implementing
        power systems.
      </p>
      <a
        href="https://qutrobotics.files.wordpress.com/2015/04/robot-power-systems.pdf"
        target="_blank"
        rel="noreferrer"
      >
        Robot Power Systems
      </a>
      <p>
        Thanks to Kyran Findlater for running this workshop and putting together
        this presentation.
      </p>
    </Wrapper>
  );
};

export default RobotPowerSystems;
