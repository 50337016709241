import React from "react";
import styled from "styled-components";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import DRC from "..//../../images/drc-header-1.png";
import QUTRCLogo from "..//../../images/qutrc_smalllogo.png";
import "../../../css/drc.css";
import Mathworks from "../../../images/sponsors/mathworks.svg";
import Boeing from "../../../images/sponsors/boeing.svg";

const Wrapper = styled.div`
  border-top: black solid 1px;
  padding-bottom: 10%;
  a {
    color: blue;
    z-index: 2;
    padding-bottom: 1%;
    text-decoration: none;

    &:hover {
      color: black;
    }
  }

  p {
    white-space: pre-wrap;
    margin-bottom: 3%;
    text-align: left;
  }

  li {
    list-style: circle;
    list-style-position: inside;
    text-align: left;
  }

  img {
    max-width: 100%;
    max-height: 60vh;
  }

  @media (max-width: 900px) {
    padding-bottom: 15%;
  }
`;

/* Can't use numbers e.g. 2021 */
const TwentyTwenty = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isXlDesktop = useMediaQuery(theme.breakpoints.only("xl"));
  return (
    <Wrapper className="drc-page page-container">
      <h1>Droid Racing Challenge 2020</h1>
      <div>
        <img src={DRC} alt="DRC-Header" />
      </div>
      <Stack direction="row" alignItems="center" justifyContent="center">
        <div>
          <img src={QUTRCLogo} alt="DRC-Header" height={"200px"} />
        </div>
        <div>
          <ul>
            <li><a className="pretty-link" href="https://l.facebook.com/l.php?u=https%3A%2F%2Fforms.gle%2FGESgRHZkDaGa7k9q9%3Ffbclid%3DIwAR042ozcaudbtQXHaEaSlO3n8MzCaXmSFPxtxqp3Mrpghxxhyjc9mw_Safg&h=AT1YhWSnL9wYBTD1hAjo6aiBNJBEH3CX6n548xHw9qDumSCuaHz7vh3zIxtX_Q4rCF7yA8c0AXjrVG_04kuzesNGK79cwXOgXfRX_hWz9gIwOXM8qQ5Ksi827eSc7ZAITyVOtw">Registration</a></li>
            <li><a className="pretty-link" href="https://qutrobotics.wordpress.com/droid-racing-challenge/drc-key-dates/">Key Dates</a></li>
            <li><a className="pretty-link" href="rules">Rules</a></li>
            <li><a className="pretty-link" href="/sponsors">Sponsors</a></li>
          </ul>
        </div>
      </Stack>
      <hr/>

      <h1 style={{ color: "red"}}>Cancelled Due to COVID-19</h1>

      
        <p>
          Beginning in 2016, QUT Robotics Club has hosted the Droid Racing
          Challenge – a friendly competition where universities from around
          Australia and the world are invited to bring their automated bots to
          traverse an obstacle course!
          <br/><br/>
          Due to the restrictions caused by the ongoing situation with COVID19,
          the 2020 droid racing challenge will be held as a simulated challenge.
          The challenge is sponsored by MathWorks, Boeing Australia, and the
          Queensland University of Technology.
          <br/><br/>
          Make sure you check back here regularly as more information is
          released. Alternatively, Like us on Facebook, and we’ll let you know
          whenever this page is updated.
        </p>
      

      <p>Best of luck to all teams!</p>
      <p>For any questions or queries contact us at{" "}
        <a href="mailto:contact@qutroboticsclub.com">contact@qutroboticsclub.com</a>
      </p>

      <hr/>

        <section id="drc-resources">
            <h2>DRC Resources</h2>
            <a href="https://www.mathworks.com/">
                <img id="mathworks-logo" className="img" src={Mathworks} alt="MathWorks logo"/>
            </a>
            <p className="justify" style={{ marginBottom: "1%" }}>
                MathWorks, makers of MATLAB and Simulink, is offering participating teams complimentary software, tutorials, and videos. They are also
                offering a prize to participants for the best implementation of MATLAB and other MathWorks software. Find out more about how technical
                computing and Model-Based Design can help you compete:
            </p>
            <a className="pretty-link" href="/drc/mathworks-resources">MathWorks Resources</a>
            <br />
            <a className="pretty-link" href="/drc/matlab-adoption-prize-rules">MathWorks Prize Rules</a>
            <br />
            <a className="pretty-link" href="/drc/mathworks-resources">General Resources</a>
        </section>



      <hr/>
      <h2>2020 Club Sponsors</h2>
      
      <Stack
        direction={isMobile ? "column" : "row"}
        alignItems="center"
        justifyContent="center"
      >
        <img src={Boeing} alt="Boeing" height={"200vh"} width={isXlDesktop ? "400vh" : "200vh"} style={{ paddingRight: "4%" }}/>
        <img src={Mathworks} alt="MathWorks" height={"200vh"} width={isXlDesktop ? "400vh" : "200vh"}/>
      </Stack>
    </Wrapper>
  );
};

export default TwentyTwenty;
