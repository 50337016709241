import React, {useEffect, useState} from "react";
import styled from "styled-components";
import Card from "../components/card";
import Grid from "@mui/material/Grid";
import PopupDialog from "../components/dialog";
import {getProjects} from "../db/data.js";
// import { projectsData } from "../db/projects";


const Wrapper = styled.div`
  margin: 3%;
  padding-bottom: 5%;

  @media (max-width: 600px) {
    padding-bottom: 15%;
  }
`;

const Projects = () => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [projectsData, setProjectsData] = useState([])

    useEffect(() => {
        getProjects()
            .then(projects => {
                setProjectsData(projects)
            })
    }, [])

  return (
    <div className="projects">
      <h1>Our Projects</h1>
      <Wrapper>
        <Grid container spacing={3} alignItems="center" justifyContent="center">
          {projectsData.sort((a, b) => a.id - b.id).map((project) => (
            <Grid item key={project.id} id={project.firestoreId} md={4} sm={6} xs={9}>
              <Card
                className="card"
                onOpen={handleClickOpen}
                project={project}
              />
              <PopupDialog
                key={project.id}
                open={open}
                onClose={handleClose}
                project={project}
              />
            </Grid>
          ))}
        </Grid>
      </Wrapper>
    </div>
  );
};

export default Projects;
