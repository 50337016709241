import React from 'react'

const Headshot = ({ exec }) => {
    return (
        <div className='headshot' id={exec.firestoreId}>
            <div className='headshot-image-border'>
                <img className='headshot-image'
                     src={require(`../images/exec_photos/${exec.imagePath ? exec.imagePath : "Robot.png"}`).default}
                     alt={exec.name} />
            </div>

            <div className='name-badge'>
                <h2>{exec.name}</h2>
                <h3>{exec?.position}</h3>
            </div>
        </div>
    )
}

export default Headshot
