import React from "react";
import { Wrapper } from "../../../components/Wrapper.styles";

const ProgrammingMicrocontrollers = () => {
  return (
    <Wrapper>
      <h1>Programming Microcontrollers in C</h1>
      <p>
        Programming is a very important part of robotics, and this workshop
        introduces the basics of how to program AVR microcontrollers in C. Here
        we focus on basic C commands and structure, then introduce
        microcontroller specific concepts like manipulating registers and using
        interrupts and timers. We’ll start by blinking some LED’s and making
        some push buttons work, but by the end of this workshop you will learn
        how to read a datasheet and figure out how to work with microcontrollers
        for yourself. A jumping off point if you will.
      </p>
      <h3>Hardware:</h3>
      <p>
        For this workshop we use the
        <a
          href="https://www.pjrc.com/teensy/index.html"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          Teensy 2.0
        </a>
        , some LEDS and push buttons, and a breadboard and wires for
        prototyping. These are all available through QUT Robotics Club.
      </p>
      <h3>Required Software:</h3>
      <div>
        <a
          href="http://sourceforge.net/projects/winavr/"
          target="_blank"
          rel="noreferrer"
        >
          WinAVR{" "}
        </a>{" "}
        OR
        <a
          href="http://www.atmel.com/tools/atmelstudio.aspx"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          Atmel Studio{" "}
        </a>
        |
        <a
          href="https://www.pjrc.com/teensy/loader.html"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          Systems Design{" "}
        </a>
      </div>
      <h3>Learning Resources:</h3>
      <div>
        <a
          href="https://qutrobotics.files.wordpress.com/2015/04/c-crash-course-slides.pdf"
          target="_blank"
          rel="noreferrer"
        >
          C crash course slides{" "}
        </a>
        |
        <a
          href="https://qutrobotics.files.wordpress.com/2015/04/teensy_schematic.pdf"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          Teensy_schematic{" "}
        </a>
        |
        <a
          href="https://qutrobotics.files.wordpress.com/2015/04/c-crash-course-examples.pdf"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          C Crash Course Examples{" "}
        </a>
        |
        <a
          href="https://qutrobotics.files.wordpress.com/2015/04/c-crash-course-mcu-examples.pdf"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          C Crash Course MCU Examples{" "}
        </a>
      </div>
      <p>
        Thanks to Rohan Smith for running this workshop and putting together
        these resources.
      </p>
    </Wrapper>
  );
};

export default ProgrammingMicrocontrollers;
