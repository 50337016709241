import React from "react";
import styled from "styled-components";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import DRC from "../../../images/drc/DRC_2017_Banner.png";
import QUTRCLogo from "..//../../images/qutrc_smalllogo.png";
import DRCResult from "../../../images/drc/DRC_2017_Results.png";
import Droids from "../../../images/drc/DRC_2017_Droids.jpg";
import WinningTeam from "../../../images/drc/2017_UNSW_Team_2.png";
import BestDesign from "../../../images/drc/2017_Design_Winners.png";
import WollongongDroid from "../../../images/drc/2017_Wollongong_Droid.png";
import "../../../css/drc.css";
import KidneyLawn from "../../../images/drc/Kidney_Lawn.jpg";
import ReactPlayer from "react-player";
import Mathworks from "../../../images/sponsors/mathworks.svg";
import Boeing from "../../../images/sponsors/boeing.svg";
import ACRV from "../../../images/sponsors/ACRV.svg";
import GYG from "../../../images/sponsors/GYG.png";

const Wrapper = styled.div`
  border-top: black solid 1px;
  padding-bottom: 10%;
  a {
    color: blue;
    z-index: 2;
    padding-bottom: 1%;
    text-decoration: none;

    &:hover {
      color: black;
    }
  }

  p {
    white-space: pre-wrap;
    margin-bottom: 3%;
    text-align: left;
  }

  h2 {
    margin-top: 3%;
  }

  li {
    list-style: circle;
    list-style-position: inside;
    text-align: left;
  }

  img {
    max-width: 100%;
    max-height: 60vh;
  }

  @media (max-width: 900px) {
    padding-bottom: 15%;
  }
`;

/* Can't use numbers e.g. 2021 */
const TwentySeventeen = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isXlDesktop = useMediaQuery(theme.breakpoints.only("xl"));
  return (
    <Wrapper className="drc-page page-container">
      <h1>Droid Racing Challenge 2017</h1>

      <div>
        <img src={DRC} alt="DRC-Header" />
      </div>

      <Stack direction="row" alignItems="center" justifyContent="center">
        <div>
          <img src={QUTRCLogo} alt="DRC-Header" height={"200px"} />
        </div>
        <div>
          <ul>
            <li><a className="pretty-link" href="https://forms.gle/TqNiK3CZaHZ67nRs5">Registration</a></li>
            <li><a className="pretty-link" href="https://qutrobotics.wordpress.com/droid-racing-challenge/drc-key-dates/">Key Dates</a></li>
            <li><a className="pretty-link" href="/drc/rules">Rules</a></li>
            <li><a className="pretty-link" href="/sponsors">Sponsors</a></li>
            <li><a className="pretty-link" href="map">Directional Map</a></li>
          </ul>
        </div>
      </Stack>
      <hr/>

      <div>
        <h2>The Challenge:</h2>
        <ul>
          <li>Task – Race around the paved path surrounding the Kidney Lawn at QUT Gardens Point.</li>
          <li>Computer Vision – Use computer vision to detect track lines and obstacles.</li>
          <li>Obstacle avoidance – Avoid any obstacles in the droid’s path and other droids.</li>
          <li>Creativity – Come up with novel designs and approaches to create the fastest droid racer.</li>
          <li>Aesthetics – Make your robot look awesome!</li>
        </ul>

        <img src={DRCResult} alt="DRC 2017 Results" />

        <p>Link to photos:{" "}
          <a href="https://www.facebook.com/media/set/?set=a.1606075016071417&type=3">2017 DRC</a>
        </p>

        <h2>Participating Universities</h2>
        <p style={{ marginBottom: "1%" }}>7 Universities from across Australia and New Zealand participated in the challenge, presenting a total of 11 teams!</p>
        <ul>
          <li>2 Teams – Griffith University (QLD)</li>
          <li>1 Team – Macquarie University (NSW)</li>
          <li>3 Teams – Queensland University of Technology (QLD)</li>
          <li>1 Team – University of Auckland (New Zealand)</li>
          <li>2 Teams – University of New South Wales (NSW)</li>
          <li>2 Teams – University of Queensland (QLD)</li>
          <li>1 Team – University of Wollongong (NSW)</li>
          <li>[Withdrawn] 1 Team – Western Sydney University (NSW)</li>
          <li>[Withdrawn] 1 Team – University of Technology Sydney (NSW)</li>
          <li>[Not present on the day] 1 Team – Edith Cowan University (WA)</li>
          <li>[Not present on the day] 1 Team – University of Queensland (QLD)</li>
        </ul>

        <h2>Key Dates</h2>
        <ul>
          <li>1st March: Introduce the competition, invite teams, and applications opened for the challenge.</li>
          <li>2nd March: Detailed competition requirements and rules published, the challenge is officially open.</li>
          <li>10th April: Applications due</li>
          <li>12th June: Progress update due</li>
          <li>3rd July: Practice and testing day for teams on the race track at QUT</li>
          <li>4th July: Final Challenge at QUT</li>
        </ul>
      </div>
        <hr/>
      <div>
        <section id="drc-resources">
              <h2>DRC Resources</h2>
              <a href="https://www.mathworks.com/">
                  <img id="mathworks-logo" className="img" src={Mathworks} alt="MathWorks logo"/>
              </a>
              <p className="justify" style={{ marginBottom: "1%" }}>
                  MathWorks, makers of MATLAB and Simulink, is offering participating teams complimentary software, tutorials, and videos. They are also
                  offering a prize to participants for the best implementation of MATLAB and other MathWorks software. Find out more about how technical
                  computing and Model-Based Design can help you compete:
              </p>
              <a className="pretty-link" href="/drc/mathworks-resources">MathWorks Resources</a>
              <br />
              <a className="pretty-link" href="/drc/matlab-adoption-prize-rules">MathWorks Prize Rules</a>
              <br />
              <a className="pretty-link" href="/drc/mathworks-resources">General Resources</a>
          </section>
      </div>
       
      <hr/>

      <div>
        <h2>Summary</h2>
        <img src={KidneyLawn} alt="DRC-Header" />
        <p>
          The Droid Racing Challenge 2017 took place on the 4th of July with
          teams from Australia and New Zealand competing. The event was a huge
          success with teams improving vastly over last year, the number of
          competing teams doubling in size and even receiving coverage from{" "}
          <a href="http://www.abc.net.au/news/2017-07-05/driverless-droid-racing-car-university-competition/8678650">ABC News</a>
        </p>
        <ReactPlayer url="https://www.youtube.com/watch?v=KAYkTM2N3b0" width="100%"/>
        <p style={{ marginBottom: "1%" }}>
          The teams initially faced off against one another in a series of round
          robin matches over three rounds. With points scored for each section
          of the track completed and winning the race and penalties for leaving
          the track or hitting an obstacle. The top 8 teams then progressed to
          the finals where the winner was declared by a selection of judges, who
          weighed the droids speed and progress along the track against the
          amount of restarts and obstacle collisions.
          <br/><br/>
          The day started off slow with teams experiencing serious issues
          detecting the lines during round 1. However from there progress was
          quick, with Macquarie University soon completing the first lap of the
          day, followed by University of Queensland team 1 and University of New
          South Wales team 2. Obstacle avoidance also improved rapidly
          throughout the day with teams adjusting and calibrating for the
          unpredictable outdoor conditions.
          <br/><br/>
          After a demonstration provided by sponsors MathWorks on ways of using
          MATLAB to control the droids. And lunch provided by sponsors Guzman y
          Gomez the following teams progressed to the finals:
        </p>
        <ul>
          <li>Griffith University</li>
          <li>Macquarie University</li>
          <li>Queensland University of Technology Team 3</li>
          <li>University of New South Wales Team 1 & 2</li>
          <li>University of Queensland Team 1 & 2</li>
          <li>University of Wollongong</li>
        </ul>
        <img style={{ marginTop: "3%" }} src={Droids} alt="Droids" />
        <p>
          In the end University of New South Wales team 2 won the day completing
          the track quickly and also demonstrating fantastic obstacle avoidance.
          University of Queensland team 1 came in second also showing off great
          obstacle avoidance followed by the team from the University of
          Macquarie in 3rd place.
        </p>
        <img style={{ marginTop: "3%" }} src={WinningTeam} alt="Winning-Team" />
        <h4 style={{ marginTop: "0" }}>1st Place UNSW team 2</h4>
        <p>
          Teams were also judged by there creativity and the aesthetics of the
          droids. For this the Best Design award went to Queensland University
          of Technology team 1 for their droid design. Honourable mentions went
          to the team from University of Wollongong which used 3 raspberry pi
          cameras to detect the lines and objects (and some sunnies to get rid
          of glare). As well as to QUT team 2 for its use of machine learning
          via a neural network opposed to traditional computer vision
          programming methods.
        </p>
        <img style={{ marginTop: "3%" }} src={BestDesign} alt="Droids" />
        <h4 style={{ marginTop: "0" }}>Design Award Winners</h4>
        <img style={{ marginTop: "3%" }} src={WollongongDroid} alt="Droids" />
        <h4 style={{ marginTop: "0" }}>University Of Wollongong’s Droid</h4>
        <p>
          The competition this year was a massive success with several teams
          completing entire laps of the course and integrating obstacle
          avoidance into the code. This was a huge improvement on last year from
          where only one team completed a full lap. DRC 2017 also saw 4 new
          universities present teams doubling the size of the competition,
          including our first international team from the University of
          Auckland.
          <br/><br/>
          This all would not have been possible without the many amazing people
          and organisations who generously gave their time and sponsorship to
          this event. The competing teams, QUT staff and students, volunteers on
          the day, and our sponsors Boeing, MathWorks, QUT, the Australian
          Centre for Robotic Vision, and Guzman y Gomez all deserve a giant
          thank you.
          <br/><br/>
          Also a huge thank you to the Club President Filippo Capurso who lead
          the executive team and pulled the event together.
        </p>
        <p>Written by: Jessie McVicar --- QUT Robotics Club Secretary 2017</p>
      </div>

      <hr/>

      <h3>2017 DRC Club Sponsors</h3>
      <Stack direction={isMobile ? "column" : "row"} alignItems="center"justifyContent="center">
        <img src={Boeing} alt="Boeing" height={"200vh"} width={isXlDesktop ? "400vh" : "200vh"} style={{ paddingRight: "4%" }}/>
        <img src={ACRV} alt="ACRV" height={"100vh"} width={isXlDesktop ? "400vh" : "200vh"} style={{ paddingRight: "4%" }}/>
        <img src={Mathworks} alt="MathWorks" height={"200vh"} width={isXlDesktop ? "400vh" : "200vh"} style={{ paddingRight: "4%" }}/>
        <img src={GYG} alt="GYG" height={"200vh"} width={isXlDesktop ? "400vh" : "200vh"}/>
      </Stack>

    </Wrapper>
  );
};

export default TwentySeventeen;
