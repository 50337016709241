import React from "react";
import styled from "styled-components";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import DRC from "..//../../images/drc-header-1.png";
import QUTRCLogo from "..//../../images/qutrc_smalllogo.png";
import "../../../css/drc.css";
import Boeing from "../../../images/sponsors/boeing.svg";
import QCR from "../../../images/sponsors/qcr.svg";

const Wrapper = styled.div`
  border-top: black solid 1px;
  padding-bottom: 10%;
  a {
    color: blue;
    z-index: 2;
    padding-bottom: 1%;
    text-decoration: none;

    &:hover {
      color: black;
    }
  }

  p {
    white-space: pre-wrap;
    margin-bottom: 3%;
    text-align: left;
  }

  li {
    list-style: circle;
    list-style-position: inside;
    text-align: left;
  }

  img {
    max-width: 100%;
    max-height: 60vh;
  }

  @media (max-width: 900px) {
    padding-bottom: 15%;
  }
`;

/* Can't use numbers e.g. 2021 */
const TwentyTwentyThree = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isXlDesktop = useMediaQuery(theme.breakpoints.only("xl"));
  return (
    <Wrapper className="drc-page page-container">
      <h1>Droid Racing Challenge 2024</h1>
      <div>
        <img src={DRC} alt="DRC-Header" />
      </div>

      <Stack direction="row" alignItems="center" justifyContent="center">
        <div>
          <img src={QUTRCLogo} alt="DRC-Header" height={"200px"} />
        </div>
        <div>
          <ul>
            <li><a className="pretty-link" href="https://docs.google.com/forms/d/1wkC9QeX2P5o9PpJmosXL0HHV7COHkU05F3F_ufJOYHU/edit">Expression of intrest</a></li>
            <li><a className="pretty-link" href="/drc/rules">Rules</a></li>
            <li><a className="pretty-link" href="/sponsors">Sponsors</a></li>
            <li><a className="pretty-link" href="map">Directional Map</a></li>
          </ul>
        </div>
      </Stack>
      <hr/>

        <p>
          QUT Robotics Club has hosted the Droid Racing Challenge (est. 2016), a friendly competition where universities 
          from around Australia and the world are invited to enter an autonomous droid to race against other teams. 
          <br/><br/>
          With a track that spans over 20 meters and teams from all over Australia, this year we are back and better then EVER! 
          Please see updated rules at the top of the page for more information! 
          This years competition will be run from 9/07/24 to 10/07/24 with the first day dedicated to final testing 
          and the second day to our official competition. Please check key dates for more dates.
        </p>


        <p style={{ marginTop: "5%" }}>Registration's opens 8th April until the 6th of June.</p>


        <p style={{ marginTop: "3%" }}>Best of luck to all teams!</p>
        <p>
          Make sure to follow our{" "}
          <a href="http://www.facebook.com/qutroboticsclub">Facebook</a> for the
          latest information. Alternatively join our{" "}
          <a href="https://discord.gg/cQa8VzVgT4">Discord</a> in the DRC Specific
          Channel (Utilise the self assign roles in #role-management)
        </p>
        <p>
          For any questions or queries contact us at{" "}
          <a href="mailto:contact@qutroboticsclub.com">contact@qutroboticsclub.com</a>
            {" "}or go to our <a href="/contact">contact page</a>.
        </p>

        <hr/>

      <h2>2024 Club Sponsors</h2>
      <Stack
        direction={isMobile ? "column" : "row"}
        alignItems="center"
        justifyContent="center"
      >
        <img src={Boeing} alt="Boeing" height={"200vh"} width={isXlDesktop ? "400vh" : "200vh"} style={{ paddingRight: "4%" }}/>
        <img src={QCR} alt="QCR" height={"200vh"} width={isXlDesktop ? "400vh" : "200vh"} style={{ paddingRight: "4%" }} />
      </Stack>
    </Wrapper>
  );
};

export default TwentyTwentyThree;
