import React from "react";
import { Wrapper } from "../../../components/Wrapper.styles";

const RoboticWorkshop = () => {
  return (
    <Wrapper>
      <h1>Robot Arm Workshop</h1>
      <p>
        In Semester 1, 2018 QUT Robotics Club held a workshop showing members
        how to design, build and control 4DOF robotic arms. The workshop series
        spent the first half of the project building Armageddon and implementing
        a position-based inverse kinematics controller that made the tool-point
        of the robot follow a trajectory given by keyboard presses and later a
        gamepad.
      </p>
      <p>
        These arms were further refined in semester 2 of 2018, with the addition
        of raspberry pi camera’s and the use of openCV for vision and grasping.
        This worked towards building a vision system for the robot to
        autonomously see a ball, given some reference points to its frame of
        reference, and plan a trajectory to pick up the ball and place it into a
        box.
      </p>
      <p>
        The Arms were constructed from HDF laser cutting, with several servo
        motors, and were powered by Raspberry Pi 3’s. They were programmed in
        python.
      </p>
      <p>
        The robot arms and the content used in this workshop series was
        developed by Andrew Razjigaev (2018 President of the QUTRC). The
        documentation, python code, lasercutting files, parts list, workshop
        slides and Matlab simulations are all on the Github repository:
      </p>
      <h2>Github Project by Andrew Razjigaev:</h2>
      <a
        href="https://github.com/Andrew-Raz-ACRV/Robotics-Club-Robot-arm"
        target="_blank"
        rel="noreferrer"
      >
        https://github.com/Andrew-Raz-ACRV/Robotics-Club-Robot-arm
      </a>
      <h3>Armageddon:</h3>
      <h5>Armageddon preparing to see the coloured balls</h5>
      <h3>Team Members/Contributors:</h3>
      <p>Andrew Razjigaev</p>
      <p>Krishan Rana</p>
      <p>Marty</p>
      <p>PDF of the Initial Documentation:</p>
      <a
        href="https://qutrobotics.files.wordpress.com/2019/07/robot-arm-workshop-project-documentation.pdf"
        target="_blank"
        rel="noreferrer"
      >
        Full Documentation Available Here!
      </a>
    </Wrapper>
  );
};

export default RoboticWorkshop;
