import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import LinkPaper from "../components/link_paper";
import Grid from "@mui/material/Grid";
import ProjectBreadcrumbs from "../components/project_breadcrumbs";
import ResourceLink from "../components/resource_link";

const Wrapper = styled.div`
  margin: 0 5% 3% 5%;
  padding-bottom: 5%;
  font-family: "Montserrat", sans-serif;
  font-size: 24px;

  p {
    margin-bottom: 5%;
    font-size: 24px;
  }

  img {
    max-width: 51vh;
  }

  h2 {
    margin-bottom: 3%;
  }

  @media (max-width: 900px) {
    margin: 0 5% 15% 5%;

    .MuiBreadcrumbs-root {
      text-align: center;
    }
  }
`;

const ProjectDetails = ({ project }) => {
  return (
    <Wrapper>
      <ProjectBreadcrumbs projectName={project.title} />
      <h1>{project.title}</h1>
      <img
        src={require(`../images/projects/${project.imagePath}`).default}
        alt={`${project.title}`}
        height={400}
        width={600}
      />
      {project.text.split("\n").map((sentence, index) => (
        <p className="left" key={index}>{sentence}</p>
      ))}
      {(project.jordanLinks || project.partsLists || project.links) && (
        <h2>Relevant Links</h2>
      )}
      {project.jordanLinks && (
        <div>
          <h3>Find out more about Jordan Letchford</h3>
          <Grid
            container
            spacing={5}
            alignItems="center"
            justifyContent="center"
          >
            {project.jordanLinks.map((link, index) => (
              <Grid item key={index} md={4} sm={6} xs={9}>
                <LinkPaper link={link} />
              </Grid>
            ))}
          </Grid>
        </div>
      )}
      {project.links && (
        <div>
          <h3>Other Links</h3>
          <Grid
            container
            spacing={5}
            alignItems="center"
            justifyContent="center"
          >
            {project.links.map((link, index) => (
              <Grid item key={index} md={4} sm={6} xs={9}>
                <LinkPaper link={link} />
              </Grid>
            ))}
          </Grid>
        </div>
      )}
      {project.partsLists && (
        <div>
          <h3>Parts Lists</h3>
          {project.partsLists.map((link, index) => (
            <ResourceLink key={index} href={link.url} label="Visit">
              {link.name}
            </ResourceLink>
          ))}
        </div>
      )}
    </Wrapper>
  );
};

ProjectDetails.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.number.isRequired,
    imagePath: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    jordanLinks: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        link: PropTypes.string,
      })
    ),
    links: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        link: PropTypes.string,
      })
    ),
    partsLists: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        link: PropTypes.string,
      })
    ),
  }),
};

ProjectDetails.defaultProps = {
  project: {},
};

export default ProjectDetails;
