import React from "react";
import { Wrapper } from "../../components/Wrapper.styles";

const Faqs = () => {
  return (
    <Wrapper>
      <h1>QUT DRC FAQ</h1>
      <h3>Is the team size limited?</h3>
      <p>
        There is a limit of 6 official members per team. We recommend having at
        least 4 per team: 2 software programmers; 1 in charge of electrical and
        1 for mechanical requirements for each team. There may be no more than
        three teams representing a participating institution.
      </p>
      <h3>Is the budget limited?</h3>
      <p>
        The total budget of the final product must be no more than $1500. As all
        components will be at the expense of the teams, a budget limit is
        imposed to ensure a level playing field.
      </p>
      <h3>
        Is it essential to have an official team supervisor from our university/high-school
        staff?
      </h3>
      <p>
        Yes, this is a requirement of the competition. Each university/high-school will be
        required to have at least one staff supervisor contact us by June 3rd
        for a progress update to ensure that the team(s) will meet the
        requirements and rules of the challenge and be ready by the final.
      </p>
      <h3>
        What components can be used? Can a robot kit/commercial robot chassis or
        similar be used?
      </h3>
      <p>
        Any components can be used, as long as they meet the rules and stay
        within the budget.
      </p>
      <h3>Are there software or processing restrictions?</h3>
      <p>
        None. Any programming language, software or processing regime may be
        used, including off board. However, any off board links must be wireless
        and present at the event, cloud based solutions are on a case by case
        basis.
      </p>
      <h3>Are postgraduates allowed to participate?</h3>
      <p>
        This competition is designed for undergraduate students, therefore a
        maximum of 1 postgraduate student is allowed per team.
      </p>
      <h3>Can we use any sensors/actuators we wish?</h3>
      <p>{`Vision should be the main navigation sensing method, but it may be supplemented with other sensing methods. LiDARS, GPS and over head camera systems are not allowed. Any motors etc. may be used.
                \nBasically if it can fit on the robot with no external wires and vision is the primary navigation system, you can use it.`}</p>
      <h3>Is the source of energy limited i.e. batteries only?</h3>
      <p>
        The source of energy is not limited to batteries. Try to avoid things
        like huge capacitors due to the risk of them blowing up. Please be aware
        of the restrictions/requirements for shipping for airlines regarding
        batteries as you will need to adhere to these requirements when
        shipping/accompanying your robot to the challenge. Do not rely on solar
        power as the competition is indoors.
      </p>
      <h3>What is the race track surface like?</h3>
      <p>
        The track is on your common hard rubber lab flooring. It will be held in
        O134, this is indoors and is almost completely flat with minor
        imperfections. We would recommend soft, wide rubber tyres at least 5cm
        in diameter like those found on remote controlled toy cars etc. The
        surface can make RC Cars drift really well.
      </p>
      <h3> What can I do if I want to start preparing now?</h3>
      <p>
        This website and the droid racing pages will be regularly updated with
        more information. You can also explore OpenCV, a well known computer
        vision library available for a number of platforms. You can investigate
        the type of sensors you might like to use for obstacle avoidance. You
        can think about what kind of mechanical design will be fastest. There
        are also previous years’ photos and videos on Facebook and YouTube to
        explore. And you can
        <a
          href="/qutrc-website/src/pages/contact"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          contact{" "}
        </a>
        us for more information.
      </p>
    </Wrapper>
  );
};

export default Faqs;
