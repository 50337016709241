import React from "react";
import styled from "styled-components";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import DRC from "../../../images/drc/DRC_2016_Banner.png";
import QUTRCLogo from "..//../../images/qutrc_smalllogo.png";
import DRCResult from "../../../images/drc/DRC_2016_Results.png";
import GovernmentHouse from "../../../images/drc/QUT_Government_House.jpg";
import ReactPlayer from "react-player";
import Mathworks from "../../../images/sponsors/mathworks.svg";
import Boeing from "../../../images/sponsors/boeing.svg";
import Insitu from "../../../images/sponsors/insitu.svg";
import ACRV from "../../../images/sponsors/ACRV.svg";
import Boost from "../../../images/sponsors/boost.png";

const Wrapper = styled.div`
  border-top: black solid 1px;
  padding-bottom: 10%;
  a {
    color: blue;
    z-index: 2;
    padding-bottom: 1%;
    text-decoration: none;

    &:hover {
      color: black;
    }
  }

  p {
    white-space: pre-wrap;
    margin-bottom: 3%;
    text-align: left;
  }

  li {
    list-style: circle;
    list-style-position: inside;
    text-align: left;
  }

  img {
    max-width: 100%;
    max-height: 60vh;
  }

  @media (max-width: 900px) {
    padding-bottom: 15%;
  }
`;

/* Can't use numbers e.g. 2021 */
const TwentySixteen = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isXlDesktop = useMediaQuery(theme.breakpoints.only("xl"));
  return (
    <Wrapper className="drc-page page-container">
      <h1>Droid Racing Challenge 2016</h1>
      <div>
        <img src={DRC} alt="DRC-Header" />
      </div>

      <Stack direction="row" alignItems="center" justifyContent="center">
        <div>
          <img src={QUTRCLogo} alt="DRC-Header" height={"200px"} />
        </div>
        <div>
          <ul>
            <li><a className="pretty-link" href="https://forms.gle/TqNiK3CZaHZ67nRs5">Registration</a></li>
            <li><a className="pretty-link" href="https://qutrobotics.wordpress.com/droid-racing-challenge/drc-key-dates/">Key Dates</a></li>
            <li><a className="pretty-link" href="/drc/rules">Rules</a></li>
            <li><a className="pretty-link" href="/sponsors">Sponsors</a></li>
            <li><a className="pretty-link" href="map">Directional Map</a></li>
          </ul>
        </div>
      </Stack>

      <hr/>

      <h2>Introduction</h2>
      <img style={{ marginTop: "3%" }} src={GovernmentHouse} alt="Government-House" />
      <h4 style={{ marginTop: "0" }}>View of the the Kidney Lawn in front of Old Government House at QUT Gardens Point Campus. Droids will race around the paved surface surrounding the lawn. Image:{" "}
        <a href="http://www.ogh.qut.edu.au/venue-hire/">http://www.ogh.qut.edu.au/venue-hire/</a>
      </h4>

      <p>
        The QUT Droid Racing Challenge (DRC) is a brand new project QUT Robotics
        Club is launching this year. The DRC is a student robotics challenge
        focusing on the development of ground based autonomous robotic racing
        platforms. The “droids” that take part in this competition will feature
        autonomous operation, robotic vision, navigation and obstacle avoidance,
        and mechanical design optimized for speed. The Challenge has been set so
        that a small team of undergraduate students should be able to complete a
        capable robot in one semester. The final competition will take place on
        the 29th of June around the Kidney Lawn at QUT Gardens Point Campus, in
        front of Old Government House. Prizes will be at stake for winning
        teams. Universities from across Australia and New Zealand were invited
        to participate.
      </p>

      <h2>The Challenge</h2>
      <ul>
        <li>Design – Build an autonomous ground based droid using any desired method and materials.</li>
        <li>Task – Race around the paved path surrounding the Kidney Lawn at QUT Gardens Point.</li>
        <li>Computer Vision – Use computer vision to detect track lines and obstacles.</li>
        <li>Obstacle avoidance – Avoid any obstacles in the droid’s path, and other droids.</li>
        <li>Creativity – Come up with novel designs and approaches to create the fastest droid racer.</li>
        <li>Aesthetics – Make your robot look awesome.</li>
      </ul>

      <img src={DRCResult} alt="DRC 2016 Results" />

      <p>Link to photos:{" "}
          <a href="https://www.facebook.com/media/set/?set=a.5387751454570402&type=3">2016 DRC</a>
      </p>

      <h2>Key Dates:</h2>
      <ul>
        <li>22 February 2016: Introduce the competition, invite teams, and applications opened for the challenge.</li>
        <li>7 March: Detailed competition requirements and rules published, challenge is officially open.</li>
        <li>1st April: Applications due</li>
        <li>3rd June: Progress update due</li>
        <li>28th June: Practice and testing day for teams on the race track at QUT</li>
        <li>29th June: Final Challenge at QUT</li>
      </ul>

      <p>
        Participation in the final will be subject to meeting all rules and
        requirements. The progress update is simply a check up to make sure that
        these will be met by the day of the challenge.
      </p>

      <hr/>

      <section id="drc-resources">
        <h2>DRC Resources</h2>
          <a href="https://www.mathworks.com/">
              <img id="mathworks-logo" className="img" src={Mathworks} alt="MathWorks logo"/>
          </a>
          <p className="justify" style={{ marginBottom: "1%" }}>
              MathWorks, makers of MATLAB and Simulink, is offering participating teams complimentary software, tutorials, and videos. They are also
              offering a prize to participants for the best implementation of MATLAB and other MathWorks software. Find out more about how technical
              computing and Model-Based Design can help you compete:
          </p>
          <a className="pretty-link" href="/drc/mathworks-resources">MathWorks Resources</a>
          <br />
          <a className="pretty-link" href="/drc/matlab-adoption-prize-rules">MathWorks Prize Rules</a>
          <br />
          <a className="pretty-link" href="/drc/mathworks-resources">General Resources</a>
      </section>

      <hr/>

      <h2>Summary</h2>
      <p>
        The task set by the QUT Robotics Club for the inaugural Droid Racing
        Challenge (DRC) was not a trivial one – to design a ground based vehicle
        capable of autonomously navigating around a track, other vehicles, and
        obstacles, using robotic vision. It is a simplified version of what
        Google, Tesla and Uber are grappling with to make cars that drive
        themselves, attempting to remove human error and making driving safer
        for everyone. Humans are very good at processing visual information, and
        making robots capable of replicating this skill was indeed a challenge.
      </p>
      <ReactPlayer
        url="https://www.youtube.com/watch?v=523I053X8xI"
        width="100%"
      />
      <p>
        Teams from QUT, UQ, Griffith and UNSW competed in head to head races
        throughout the day. The deserving winners (by virtue of being the only
        team to fully complete the track) were UNSW who timed 4 minutes 13
        seconds. UQ gave a solid effort coming in second place, and one of the
        QUT teams snagged third place. It was a tough event, but all competitors
        agreed it was worthwhile and are keen to come back next year. Robotics
        competitions have a history of teams struggling the first time around,
        then coming back and smashing it once they have some experience with the
        challenge; take the DARPA Grand Challenges for instance. The 2004 DARPA
        Grand Challenge was a driverless car competition with a 240 km route and
        15 teams in the final. That year, none of the entrants completed the
        challenge, with the best effort traveling a mere 11.78 km.
        <br/><br/>
        The 2005 DARPA Grand Challenge had 23 teams take part, with all but one
        besting the previous year’s record, and five completing the full course.
        The teams at the Droid Racing Challenge did a bit better than that
        (relatively speaking), and if history is anything to go on we will see
        some impressive performances next year.
        <br/><br/>
        Building great robots was not the only goal of the DRC. We wanted to
        introduce an Australian robotics challenge that was new, interesting,
        and inspired innovation in a field that is brimming with real-world
        potential. It was fantastic to see how each of the teams fully engaged
        with the challenge and talked with each other about their different
        approaches to the task. The DRC also brought the QUT and Brisbane
        communities closer, who were excited to see something like this in the
        city. Below we have listed some of the coverage the event received. The
        audio at the start of this article is from an evening radio spot on 612
        ABC Brisbane, who also did a live cross from the event in the morning.
      </p>
      <p>
        <a href="https://www.qut.edu.au/about/news/news?news-id=106745">
          Race into QUT: These are the droids you’re looking for…
        </a>
      </p>
      <p>
        This event may have started with the QUT Robotics Club, but would not
        have been possible without many people and organisations who generously
        gave their time and sponsorship so that this event could happen. The
        competing teams, QUT staff and students, volunteers on the day, and our
        sponsors Boeing, Insitu Pacific, QUT School of Electrical Engineering
        and Computer Science, the Australian Centre for Robotic Vision, and
        Boost Juice are all deserving of thanks. The individuals who made this
        happen know who they are, and we are extremely grateful for their
        contributions.
        <br/><br/>
        The Droid Racing Challenge also marks a rotation of the current
        executive team, who lead the QUT Robotics Club in 2014 & 2015. Some of
        us have been around for two or so years now! With all of the crew moving
        on, I would just like to say that I am extremely proud of my time with
        the Club, the work my fellow executives have done, and where we have
        left it for the new team to take over. Good luck for the year ahead!
      </p>
      <p>Written by: Lachlan Robinson --- DRC Project Manager 2016</p>

      <hr/>

      <h3>2016 DRC Club Sponsors</h3>
      <Stack direction={isMobile ? "column" : "row"} alignItems="center"justifyContent="center" useFlexGap flexWrap="wrap">
        <img src={Boeing} alt="Boeing" height={"200vh"} width={isXlDesktop ? "400vh" : "200vh"} style={{ paddingRight: "4%" }}/>
        <img src={Insitu} alt="Insitu" height={"200vh"} width={isXlDesktop ? "400vh" : "200vh"} style={{ paddingRight: "4%" }}/>
        <img src={ACRV} alt="ACRV" height={"100vh"} width={isXlDesktop ? "400vh" : "200vh"} style={{ paddingRight: "4%" }}/>
        <img src={Mathworks} alt="MathWorks" height={"200vh"} width={isXlDesktop ? "400vh" : "200vh"} style={{ paddingRight: "4%" }}/>
        <img src={Boost} alt="Boost" height={"200vh"} width={isXlDesktop ? "400vh" : "200vh"}/>
      </Stack>
    </Wrapper>
  );
};

export default TwentySixteen;
