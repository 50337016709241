import React from "react";
import styled from "styled-components";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import DRC from "..//../../images/drc-header-1.png";
import QUTRCLogo from "..//../../images/qutrc_smalllogo.png";
import "../../../css/drc.css";
import DRCResult from "../../../images/drc/DRC_2021_Results.png";
import Mathworks from "../../../images/sponsors/mathworks.svg";
import Boeing from "../../../images/sponsors/boeing.svg";
import QCR from "../../../images/sponsors/qcr.svg";

const Wrapper = styled.div`
  border-top: black solid 1px;
  padding-bottom: 10%;

  a {
    color: blue;
    z-index: 2;
    padding-bottom: 1%;
    text-decoration: none;

    &:hover {
      color: black;
    }
  }

  p {
    white-space: pre-wrap;
    margin-bottom: 3%;
    text-align: left;
  }

  li {
    list-style: circle;
    list-style-position: inside;
    text-align: left;
  }

  img {
    max-width: 100%;
    max-height: 60vh;
  }

  @media (max-width: 900px) {
    padding-bottom: 15%;
  }
`;

/* Can't use numbers e.g. 2021 */
const TwentyTwentyone = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isXlDesktop = useMediaQuery(theme.breakpoints.only("xl"));
  return (
    <Wrapper className="drc-page page-container">
      <h1>Droid Racing Challenge 2021</h1>
      <div>
        <img src={DRC} alt="DRC-Header" />
      </div>

      <Stack direction="row" alignItems="center" justifyContent="center">
        <div>
          <img src={QUTRCLogo} alt="DRC-Header" height={"200px"} />
        </div>
        <div>
          <ul>
            <li><a className="pretty-link" href="https://forms.gle/bRyG9e7GdhiABXAx8">Registration</a></li>
            <li><a className="pretty-link" href="https://qutrobotics.wordpress.com/droid-racing-challenge/drc-key-dates/">Key Dates</a></li>
            <li><a className="pretty-link" href="/drc/rules">Rules</a></li>
            <li><a className="pretty-link" href="/sponsors">Sponsors</a></li>
          </ul>
        </div>
      </Stack>
      <hr/>


        <p>
          Beginning in 2016, QUT Robotics Club has hosted the Droid Racing
          Challenge – a friendly competition where universities from around
          Australia and the world are invited to bring their automated bots to
          traverse an obstacle course!
          <br/><br/>
          Sadly, our 2020 competition had to be cancelled due to COVID-19, but
          we are excited to be running it again this year!
          <br/><br/>
          Congratulations to the three QLD teams from QUT that were able to
          compete on the 13th July it was a great day with some good racing, an
          intriguing presentation by Bradley Horton from MathWorks on the
          resources MathWorks provides for DRC and a successful stream to an
          international audience. We look forward to the NSW teams competing in
          the future once restrictions are lifted so follow our socials to find
          out when that is happening.
        </p>

      <img src={DRCResult} alt="DRC-2021-Result" />

      <p>Link to photos:{" "}
        <a href="https://drive.google.com/drive/folders/1zWRN-TpXOqCepwuu9eCkagXH7nGucFfk?usp=sharing">2021 DRC QLD Event</a>
      </p>

      <p>
        To NSW teams we are allowing registration right up until Testing Day for
        DRC 2021 so feel free to get in contact with us via email about this:{" "}
        <a href="mailto:contact@qutroboticsclub.com">contact@qutroboticsclub.com</a>
        . There are changes to the rules this year so please be sure to read
        them. Otherwise, we hope to see you compete later this year.
      </p>

      <p style={{ marginTop: "3%" }}>Best of luck to all teams!</p>
        <p>
          Make sure to follow our{" "}
          <a href="http://www.facebook.com/qutroboticsclub">Facebook</a> for the
          latest information. Alternatively join our{" "}
          <a href="https://discord.gg/cQa8VzVgT4">Discord</a> in the DRC Specific
          Channel (Utilise the self assign roles in #role-management)
        </p>
        <p>
          For any questions or queries contact us at{" "}
          <a href="mailto:contact@qutroboticsclub.com">contact@qutroboticsclub.com</a>
            {" "}or go to our <a href="/contact">contact page</a>.
        </p>

        <hr/>

        <section id="drc-resources">
            <h2>DRC Resources</h2>
            <a href="https://www.mathworks.com/">
                <img id="mathworks-logo" className="img" src={Mathworks} alt="MathWorks logo"/>
            </a>
            <p className="justify" style={{ marginBottom: "1%" }}>
                MathWorks, makers of MATLAB and Simulink, is offering participating teams complimentary software, tutorials, and videos. They are also
                offering a prize to participants for the best implementation of MATLAB and other MathWorks software. Find out more about how technical
                computing and Model-Based Design can help you compete:
            </p>
            <a className="pretty-link" href="/drc/mathworks-resources">MathWorks Resources</a>
            <br />
            <a className="pretty-link" href="/drc/matlab-adoption-prize-rules">MathWorks Prize Rules</a>
            <br />
            <a className="pretty-link" href="/drc/mathworks-resources">General Resources</a>
        </section>


        <hr/>
      <h2>2021 Club Sponsors</h2>
      <Stack
        direction={isMobile ? "column" : "row"}
        alignItems="center"
        justifyContent="center"
      >
        <img src={Boeing} alt="Boeing" height={"200vh"} width={isXlDesktop ? "400vh" : "200vh"} style={{ paddingRight: "4%" }}/>
        <img src={QCR} alt="QCR" height={"200vh"} width={isXlDesktop ? "400vh" : "200vh"} style={{ paddingRight: "4%" }}/>
        <img src={Mathworks} alt="MathWorks" height={"200vh"} width={isXlDesktop ? "400vh" : "200vh"}/>
      </Stack>
    </Wrapper>
  );
};

export default TwentyTwentyone;
