import React, {useEffect, useState} from "react";
import styled from "styled-components";
import GitHub from "@material-ui/icons/GitHub";
import LinkedIn from "@material-ui/icons/LinkedIn";
import Headshot from "../components/headshot";
import {getDevs} from "../db/data.js";

export const Wrapper = styled.div`
  margin-bottom: 10%;
  margin-top: -5%;
  @media (max-width: 600px) {
    margin-top: -15%;
  }
`;

const Developers = () => {
    const [developers, setDevelopers] = useState([])

    useEffect(() => {
        getDevs()
            .then(devs => {
                setDevelopers(devs)
            })
    }, [])

  return (
    <div className="developers-page">
      <h1>Developers</h1>
      <ul className="headshot-container">

        {developers.map(developer => (
          <li key={developer.id} id={developer.firestoreId} className="developer">
            <Headshot exec={developer} />

            <div className="developer-links">
              <a href={developer.github} target="__blank">
                <GitHub style={{ color: "#0f1627", fontSize: 75, marginTop: "5%" }} />
              </a>
              {developer?.linkedin && (
                <a href={developer.linkedin} target="__blank">
                  <LinkedIn style={{ color: "#0f1627", fontSize: 75 }} />
                </a>
              )}
            </div>

          </li>
        ))}
      </ul>
    </div>
  );
};

export default Developers;
