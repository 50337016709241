import React from "react";
import styled from "styled-components";
import { Wrapper } from "../../components/Wrapper.styles";
import ReactPlayer from "react-player";
import Team from "../../images/resource_links/Wrap_up_team.jpg";
import Banner from "../../images/resource_links/Drc_2016_banner.jpg";

const Author = styled.div`
  text-align: left;
`;
const DrcWrapUp = () => {
  return (
    <Wrapper>
      <h1>Droid Racing Challenge Wrap-up</h1>
      
      <h3><a href="/drc/2016">Droid Racing Challenge Project Page</a></h3>
      <h3><a href="/drc/autonomous-vehicle-guide">How to make an autonomous vehicle – the 2016 Droid Racing Challenge</a></h3>
      
      <p>
        The task set by the QUT Robotics Club for the inaugural Droid Racing
        Challenge (DRC) was not a trivial one – to design a ground based vehicle
        capable of autonomously navigating around a track, other vehicles, and
        obstacles, using robotic vision. It is a simplified version of what
        Google, Tesla and Uber are grappling with to make cars that drive
        themselves, attempting to remove human error and making driving safer
        for everyone. Humans are very good at processing visual information, and
        making robots capable of replicating this skill was indeed a challenge.
      </p>
      
      <ReactPlayer
        url="https://www.youtube.com/watch?v=523I053X8xI&ab_channel=QUTRoboticsClub"
        width="100%"
      />
      
      <p>
        Teams from QUT, UQ, Griffith and UNSW competed in head to head races
        throughout the day. The deserving winners (by virtue of being the only
        team to fully complete the track) were UNSW who timed 4 minutes 13
        seconds. UQ gave a solid effort coming in second place, and one of the
        QUT teams snagged third place. It was a tough event, but all competitors
        agreed it was worthwhile and are keen to come back next year. Robotics
        competitions have a history of teams struggling the first time around,
        then coming back and smashing it once they have some experience with the
        challenge; take the DARPA Grand Challenges for instance. The 2004 DARPA
        Grand Challenge was a driverless car competition with a 240 km route and
        15 teams in the final. That year, none of the entrants completed the
        challenge, with the best effort traveling a mere 11.78 km.
        <br/><br/>
        The 2005 DARPA Grand Challenge had 23 teams take part, with all but one
        besting the previous year’s record, and five completing the full course.
        The teams at the Droid Racing Challenge did a bit better than that
        (relatively speaking), and if history is anything to go on we will see
        some impressive performances next year.
      </p>

      <img src={Team} alt="Team" />

      <p>
        Building great robots was not the only goal of the DRC. We wanted to
        introduce an Australian robotics challenge that was new, interesting,
        and inspired innovation in a field that is brimming with real-world
        potential. It was fantastic to see how each of the teams fully engaged
        with the challenge and talked with each other about their different
        approaches to the task. The DRC also brought the QUT and Brisbane
        communities closer, who were excited to see something like this in the
        city. Below we have listed some of the coverage the event received. The
        audio at the start of this article is from an evening radio spot on 612
        ABC Brisbane, who also did a live cross from the event in the morning.
      </p>

      <h3><a href="https://www.qut.edu.au/news?news-id=106745">Race into QUT: These are the droids you’re looking for…</a></h3>

      <p>
        This event may have started with the QUT Robotics Club, but would not
        have been possible without many people and organisations who generously
        gave their time and sponsorship so that this event could happen. The
        competing teams, QUT staff and students, volunteers on the day, and our
        sponsors Boeing, Insitu Pacific, QUT School of Electrical Engineering
        and Computer Science, the Australian Centre for Robotic Vision, and
        Boost Juice are all deserving of thanks. The individuals who made this
        happen know who they are, and we are extremely grateful for their
        contributions.
      </p>

      {/* // One image is used instead of a gallery like the original page. Should be a future addition*/}
      <img src={Banner} alt="Banner" />
      
      <p>
        The Droid Racing Challenge also marks a rotation of the current
        executive team, who lead the QUT Robotics Club in 2014 & 2015. Some of
        us have been around for two or so years now! With all of the crew moving
        on, I would just like to say that I am extremely proud of my time with
        the Club, the work my fellow executives have done, and where we have
        left it for the new team to take over. Good luck for the year ahead!
      </p>
      
      <Author>
        <h4>Lachlan Robinson</h4>
        <h4>DRC Project Manager</h4>
      </Author>
    </Wrapper>
  );
};

export default DrcWrapUp;
