import React from "react";
import {NavLink} from "react-router-dom";

const NotFound = () => {
	return (
		<div className="not-found page-container">
			<h1>404</h1>
			<h2>Oops, we can't find your page</h2>
			<p>Sorry, unfortunately we can't find the page you're looking for. We are currently in the process of transitioning from our old website, and ask for your patience as we fix any broken links. Thank you.</p>
			<NavLink className="nav-link" to="/">
				Go back to home
			</NavLink>
		</div>
	);
}
export default NotFound;