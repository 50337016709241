import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  border-top: black solid 1px;
  margin: 0 15%;
  padding-bottom: 10%;

  a {
    color: blue;
    z-index: 2;
    padding-bottom: 5%;
    text-decoration: none;

    &:hover {
      color: black;
    }
  }

  p {
    white-space: pre-wrap;
    margin-bottom: 5%;
  }

  li {
    list-style: circle;
    list-style-position: inside;
    margin-bottom: 0.5%;
    margin-top: 0.5%;
  }

  h5 {
    margin-bottom: 5%;
  }

  img {
    max-width: 100%;
    max-height: 60vh;
  }

  @media (max-width: 900px) {
    h5 {
      margin-bottom: 10%;
    }

    padding-bottom: 15%;
  }
`;

const PrizeRules = () => {
  return (
    <Wrapper>
      <h1 style={{ marginBottom: "3%" }}>Matlab Adoption Prize Rules</h1>
      <h2 style={{ marginBottom: "3%" }}>Best usage of MATLAB and Simulink at the 2022 Droid Racing Challenge (DRC)</h2>

      <h3>What is it and Why is it being done:</h3>
        <p className="left" style={{ marginBottom: "0" }}>
          MathWorks has been a sponsor of the DRC for the last four years and
          during that time has seen some remarkable innovation and teamwork
          displayed by the competing teams. We’d like to encourage and reward
          teams to continue exploring and innovating in 2022.
          <br></br><br></br>
          The MathWorks will be awarding prizes to the top 3 teams that
          demonstrate the best application of MATLAB and Simulink to their
          designs. The MATLAB Adoption Prizes (MAP) are:
        </p>
        <ul>
          <li>1st prize: $300</li>
          <li>2nd prize: $200</li>
          <li>3rd prize: $100</li>
        </ul>


      <p className="left" style={{ marginBottom: "0", fontWeight: "bold" }}>NOTE:</p>
        <p className="left">
          1. Participation in the MATLAB Adoption Prizes (MAP) is an optional part
          of the Droid Racing Challenge student competition.
          <br></br><br></br>
          2. All students competing in the DRC will get access to a free Student
          Competition license of MATLAB (and 80+ toolboxes) for the duration of
          the DRC competition – request your license&nbsp;
          <a href="https://www.mathworks.com/academia/student-competitions/droid-racing.html" target="_blank" rel="noreferrer">
          HERE
          </a>
        .
        </p>


      <h3 style={{ marginTop: "2%" }}>How will the winners be judged?</h3>
      <div className="left">
        <p style={{ marginBottom: "0" }}>
          Teams interested in qualifying for a MATLAB Adoption Prize (MAP), need to:
        </p>
          <ul style={{ marginBottom: "2%" }}>
            <li>Describe how MATLAB/Simulink supported their design.</li>
            <li>Describe the benefits/impact that were gained by using MATLAB/Simulink.</li>
          </ul>
        <p style={{ marginBottom: "0" }}>
          Teams will need to submit a presentation (eg: PowerPoint or Word) that addresses the MAP topics listed below:
        </p>
          <ul style={{ marginBottom: "0" , marginTop: "0" }}>
            <li>MAP_01 (20 points): The MATLAB help Browser</li>
            <li>MAP_02 (20 points): Vehicle Modelling and the role it played in your design</li>
            <li>MAP_03 (20 points): Prototyping a Computer Vision algorithm</li>
            <li>MAP_04 (20 points): Developing a Control strategy</li>
            <li>MAP_05 (20 points): Deploying the Computer Vision and Control system</li>
          </ul>
        <p style={{ marginBottom: "0" }}> 
          Points will be awarded for each Topic – as shown above.</p>
        <p>
          A MathWorks review committee will judge the submitted presentations and decide the winners.
        </p>
      </div>


      <h3>What are the deliverables that need to be submitted?</h3>
      <div className="left">
        <p style={{ marginBottom: "0" }}>
          Teams interested in qualifying for a MAP will need to submit the following:
        </p>
        <ul>
          <li>A Powerpoint or WORD (or Similar) presentation that addresses the MAP topics listed below.</li>
          <li>The presentation file needs to be submitted by midnight on 13th-July-2022</li>
          <li>The presentation should be emailed to Bradley Horton at <a href="mailto:bhorton@mathworks.com"> bhorton@mathworks.com</a></li>
          <li>Large presentations that are unreasonable to be included as an email attachment, should be placed in a dropbox folder(or similar) and a
            download link forwarded to Bradley </li>
          <li>Teams will need to make themselves available for a 20-minute team interview with a MathWorks engineer on 15th-July-2022 (exact times
            will be scheduled closer to the event)</li>
          <li>This will be a sit-down session where teams get to field questions about their presentation.</li>
          <li>It will be a “Flag and Discuss” Question and Answer session …. And NOT a “seminar” type presentation delivery</li>
          <li>During the interview, teams may be asked to “show/demonstrate” the claims made in their presentations.</li>
          <li>Eg: “You said you used MATLAB to do XYZ …. So show me”</li>
          <li>So bring a laptop along to the interview and have it ready to go.</li>
        </ul>
      </div>


      <h3 style={{ marginTop: "5%" }}>Topics to be addressed in your MAP presentation:</h3>
      <div className="left">
        <p style={{ marginBottom: "0" }}>
          Teams should comment on as many of the MAP topics/questions listed below, as they can. The maximum points that can be awarded for each topic is displayed.
        </p>
        <p style={{ marginBottom: "0" }}>The purpose of this presentation is to convey:</p>
        <ul>
          <li>How MATLAB/Simulink supported your design.</li>
          <li>What were the benefits/impact that were gained by using MATLAB/Simulink.</li>
        </ul>
      </div>


      <h3 style={{ marginTop: "5%" }}>MAP_01 (20 points): The MATLAB help Browser</h3>
      <div className="left">
        <p style={{ marginBottom: "0" }} >WHY? – Why are you being asked to discuss/comment on this?</p>
        <ul>
          <li>
            <a href="https://au.mathworks.com/products.html?s_tid=gn_ps" target="_blank" rel="noreferrer">
              MATLAB has 80+ products
            </a>
            , dozens of APPS and 1,000’s of functions and built in examples that can support you. The HELP browser will help you find them.
          </li>
        </ul>
        <p style={{ marginBottom: "0" }}>Comment on how the MATLAB Help Browser was used by the team?</p>
        <ul>
          <li>Did it help with finding functions that you needed – Which ones?</li>
          <li>Did it help with finding examples – Which ones?</li>
          <li>Did it help explain how to use APPS – Which ones?</li>
          <li>Did you discover anything using the HELP browser that shocked you?</li>
          <li>eg: I had no idea that you could do XYZ in MATLAB</li>
        </ul>
      </div>

      <h3 style={{ marginTop: "5%" }}>MAP_02 (20 points): Vehicle Modelling and the role it played in your design</h3>
      <div className="left">
        <p style={{ marginBottom: "0" }}>WHY? – Why are you being asked to discuss/comment on this?</p>
        <ul>
          <li>
            Developing a vehicle model (either kinematic OR dynamic) sounds like it should play an important role in your design. If you worked at
            Tesla, Google, on similar autonomous vehicle projects you would probably expect that someone has developed a math model of the vehicle
            which in turn is used to support the design of the autonomous systems.
          </li>
        </ul>
        <p style={{ marginBottom: "0" }}>Comment on the following:</p>
        <ul>
          <li>What did you model? (eg: kinematics, dynamics, how many dof, etc)</li>
          <li>How did MATLAB and Simulink support this?</li>
          <li>Did you use measurements from your real vehicle to tune the behaviour of your model?</li>
          <li>How do you know if your model is a good representation of reality?</li>
          <li>In order to make the model behave like your real vehicle …. What did you tune?</li>
          <li>Complete these 2 sentences:</li>
          <ul>
            <li>“By creating a vehicle model with MATLAB we were able to ….. (some GOOD thing)”
            <br></br>&emsp;&ensp;We’re looking for a genuine benefit that you experienced … NOT a marketing spiel.</li>
            <li>“Had we NOT created and used this Vehicle model then …. (some BAD thing might have happened)”	
            <br></br>&emsp;&ensp;Same comment as above. Please convey a genuine issue/challenge that was avoided/improved.</li>
          </ul>
        </ul>
      </div>

      <h3 style={{ marginTop: "5%" }}>MAP_03 (20 points): Prototyping a Computer Vision algorithm</h3>
      <div className="left">
        <p style={{ marginBottom: "0" }}>WHY? – Why are you being asked to discuss/comment on this?</p>
        <ul>
          <li>Clearly this is a core design task. MATLAB and Simulink have 100’s of computer vision algorithms which you can utilise.</li>
        </ul>
        <p style={{ marginBottom: "0" }}>Comment on the following:</p>
        <ul>
          <li>What did you develop?</li>
          <li>Explain at a high level without giving away your “secret sauce”.</li>
          <li>How did MATLAB and Simulink support this?</li>
          <li>Did you use the{" "}
            <a href="https://au.mathworks.com/products/image.html" target="_blank" rel="noreferrer">Image processing Toolbox</a>{" "}
            ? – How?
          </li>
          <li>
            Did you use the{" "}
            <a href="https://au.mathworks.com/products/computer-vision.html" target="_blank" rel="noreferrer">Computer Vision System Toolbox?{" "}</a>
            – How?
          </li>
          <li>Did you discover/use any of the Computer Vision APPS that are in MATLAB? – which ones?</li>
          <li>Did the{" "}
            <a href="https://au.mathworks.com/hardware-support/raspberry-pi-simulink.html" target="_blank" rel="noreferrer">Raspberry Pi target support package</a>{" "}
            play a role in fine tuning your computer vision algorithm?
          </li>
          <li>Call out your top 3 things in MATLAB (any features/functions/apps/examples) that were the most useful.</li>
          <li>Call out 3 things(eg: a feature/function/algorithm) that you could NOT find in MATLAB</li>
          <li>Tip: Was the MATLAB Help browser consulted?</li>
        </ul>
      </div>

      <h3 style={{ marginTop: "5%" }}>MAP_04 (20 points): Developing a Control strategy</h3>
      <div className="left">
        <p style={{ marginBottom: "0" }}>WHY? – Why are you being asked to discuss/comment on this?</p>
        <ul>
          <li>
            Once you’ve developed a computer vision algorithm that “finds stuff”, decisions then need to be made on how to control the vehicle. Eg:
            speed control, steering control, decision logic for obstacle avoidance, etc.
          </li>
        </ul>
        <p style={{ marginBottom: "0" }}>Comment on how the MATLAB Help Browser was used by the team?</p>
        <ul>
          <li>What did you develop?</li>
          <li>Explain at a high level without giving away your “secret sauce”</li>
          <li>How did MATLAB and Simulink support this?</li>
          <li>Were speed controllers designed for your motors?</li>
          <li>If so: How was MATLAB used?</li>
          <li>
            Did you use{" "}
            <a href="https://au.mathworks.com/products/stateflow.html" target="_blank" rel="noreferrer">
              Stateflow
            </a>{" "}
            to develop Finite state machines for “managing” decisions/strategies?
          </li>
          <li>If so comment on how they were used.</li>
        </ul>
      </div>

      <h3 style={{ marginTop: "5%" }}>MAP_05 (20 points): Deploying the Computer Vision and Control system</h3>
      <div className="left">
        <p style={{ marginBottom: "0" }}>WHY? – Why are you being asked to discuss/comment on this?</p>
        <ul>
          <li>Designing a concept is one thing. But getting that design running on Silicon is the end goal.</li>
          <li>You can prototype your design and then automate the conversion of that design into an implementation language. That sounds useful.</li>
        </ul>
        <p style={{ marginBottom: "1%" }}>MATLAB has numerous products that convert MATLAB/Simulink into implementation languages</p>
        <ul>
          <li>eg:{" "}
            <a href="https://au.mathworks.com/products/matlab-coder.html" target="_blank" rel="noreferrer">MATLAB Coder</a>,
            <a href="https://au.mathworks.com/products/gpu-coder.html" target="_blank" rel="noreferrer">{" "}GPU Coder</a>,
            <a href="https://au.mathworks.com/products/embedded-coder.html" target="_blank" rel="noreferrer">{" "}Embedded Coder</a>,
            <a href="https://au.mathworks.com/products/simulink-coder.html" target="_blank" rel="noreferrer">{" "}Simulink Coder</a>
          </li>
        </ul>
        <p style={{ marginBottom: "1%" }}>Comment on the following:</p>
        <ul>
          <li>Comment on the Embedded system(ie: silicon) that you programmed.</li>
          <li>What was it? (eg: NUC, Pi, Jetson)</li>
          <li>Comment on the implementation language used to programme these devices.</li>
          <li>Was it C/C++?</li>
          <li>How was MATLAB/Simulink’s code generation products utilised?
          <br></br>&emsp;
          ie:{" "}
            <a href="https://au.mathworks.com/products/matlab-coder.html" target="_blank" rel="noreferrer">MATLAB Coder</a>,
            <a href="https://au.mathworks.com/products/gpu-coder.html" target="_blank" rel="noreferrer">{" "}GPU Coder</a>,
            <a href="https://au.mathworks.com/products/embedded-coder.html" target="_blank" rel="noreferrer">{" "}Embedded Coder</a>,
            <a href="https://au.mathworks.com/products/simulink-coder.html" target="_blank" rel="noreferrer">{" "}Simulink Coder</a>
          </li>
          <li>Which parts of your design did you use these products for?</li>
          <li>Did the MATLAB help Browser help you “ramp up” on using these products?</li>
        </ul>
      </div>
    </Wrapper>
  );
};

export default PrizeRules;
