import React from "react";
import { Wrapper } from "../../../components/Wrapper.styles";

const Soldering = () => {
  return (
    <Wrapper>
      <h1>Soldering</h1>
      <p>
        We regularly have to solder circuits and things in robotics, and this
        workshop introduces you to soldering components into PCBs and
        prototyping boards. We have found this free comic on soldering very
        useful:
      </p>
      <a
        href="http://mightyohm.com/files/soldercomic/FullSolderComic_EN.pdf"
        target="_blank"
        rel="noreferrer"
      >
        Soldering is easy – here’s how to do it
      </a>
    </Wrapper>
  );
};

export default Soldering;
