import React from "react";
import DirectionalMap from "../../images/drc/Directional_Map.png";
import "../../css/drc.css";

const Map = () => {
  return (
    <div className="map">
      <h1>DRC Directional Map 2023</h1>
      <img src={DirectionalMap} alt="Directional-Map" />
    </div>
  );
};

export default Map;
