import styled from "styled-components";

export const Wrapper = styled.div`
  border-top: black solid 1px;
  margin: 0 15%;
  padding-bottom: 10%;

  a {
    color: blue;
    z-index: 2;
    padding-bottom: 5%;
    text-decoration: none;

    &:hover {
      color: black;
    }
  }

  p {
    white-space: pre-wrap;
    margin-bottom: 5%;
    text-align: left;
  }

  li {
    list-style: circle;
    list-style-position: inside;
    text-align: left;
  }

  h5 {
    margin-bottom: 5%;
  }

  img {
    max-width: 100%;
    max-height: 60vh;
  }

  @media (max-width: 900px) {
    h5 {
      margin-bottom: 10%;
    }

    padding-bottom: 15%;
  }
`;
