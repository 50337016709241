import React, { useState } from 'react'
import styled from "styled-components"
import Paper from '@mui/material/Paper'

const Wrapper = styled.div`
	.MuiPaper-root{
        img{
        max-width:100%;
        max-height: 100%;
        }
    }
`

// CSS code grabbed from https://getcssscan.com/css-buttons-examples (button 9)
const Button = styled.button`
  appearance: button;
  backface-visibility: hidden;
  background-color: #112236;
  border-radius: 10vh;
  border-width: 0;
  box-shadow: rgba(50, 50, 93, .1) 0 0 0 1px inset,rgba(50, 50, 93, .1) 0 2px 5px 0,rgba(0, 0, 0, .07) 0 1px 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: -apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Ubuntu,sans-serif;
  font-size: 100%;
  height: 44px;
  line-height: 1.15;
  margin: 0 0 5% 0;
  outline: none;
  overflow: hidden;
  padding: 0 25px;
  position: relative;
  text-align: center;
  text-transform: none;
  transform: translateZ(0);
  transition: all .2s,box-shadow .08s ease-in;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  /* width: 100%; */

&:disabled {
  cursor: default;
}

&:focus {
  box-shadow: rgba(50, 50, 93, .1) 0 0 0 1px inset, rgba(50, 50, 93, .2) 0 6px 15px 0, rgba(0, 0, 0, .1) 0 2px 2px 0, rgba(50, 151, 211, .3) 0 0 0 4px;
}
`
const LinkPaper = ({ link }) => {
    const [elevation, setElevation] = useState(1)

    const onMouseOver = () => setElevation(18)

    const onMouseOut = () => setElevation(1)

    return (
        <Wrapper>
            <Paper elevation={elevation} onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
                <div>
                    <img
                        src={require(`../images/link_thumbnails/${link.imagePath}`).default}
                        alt={link.name}
                        height={150} />
                </div>
                <div>
                    <h3>{link.name}</h3>
                    <a href={link.url} target='_blank' rel='noreferrer'>
                        <Button>Visit</Button>
                    </a>
                </div>
            </Paper>
        </Wrapper >
    )
}

export default LinkPaper
