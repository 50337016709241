import { useEffect } from "react";
import { useLocation } from "react-router-dom";

/* Code grabbed from https://stackoverflow.com/questions/64361996/react-router-scroll-page-to-the-top-after-transition */
export default function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollY = 0
    }, [pathname]);

    return null;
}