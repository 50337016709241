import styled from "styled-components";
import React, { useState } from 'react';
import PopupDialog from "./dialog";

const ProjectCard = styled.div`
    color:black;
    border:5px solid black;
    text-align:center;
    /* margin:0 auto; */
    padding:3%;
    transition: transform .2s;
    background-color:#112236;
    border-radius:20px;
    /* box-sizing:border-box; */
    :hover {
        transform: scale(1.05); 
      }

    img {
        max-height: 200px;
        width: 100%;
        object-fit: contain;
        /* border-radius: 25px; */
        cursor:pointer;
    }

    h1{
        color: #fff;
    }
`

const Card = ({ onOpen, project }) => {
    const [open, setOpen] = useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <ProjectCard>
            <img src={require(`../images/projects/${project.imagePath}`).default}
                alt={`${project.title}`}
                height={200}
                width={300}
                onClick={() => handleClickOpen()}
            />
            <h1>{project.title}</h1>
            <PopupDialog
                key={project.id}
                open={open}
                onClose={handleClose}
                project={project}
            />
        </ProjectCard>
    )
};

export default Card;
