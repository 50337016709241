import '../css/navbar.css'
import { NavLink } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';

import QUTRC_Logo from "../images/qutrc_logo.png";
import getmenuItems from './menuItems';

export default function Navbar() {
    const [isNavExpanded, setIsNavExpanded] = useState(false)
    const btnRef = useRef();

    const [menuItems, setmenuItems] = useState([])
    useEffect(() => {
        getmenuItems()
            .then(menuItems => {
                setmenuItems(menuItems)
            })
    }, [])



    // If anywhere but the dropdown button is clicked, dropdown menu is closed
    useEffect(() => {
        const closeDropdown = e => {
            let disable = true;

            e.composedPath().forEach((i) => {
                if (i === btnRef.current) {
                    disable = false;
                }
            })

            if (disable) {
                setIsNavExpanded(false)
            }
        };

        document.body.addEventListener('click', closeDropdown);

        return () => document.body.removeEventListener('click', closeDropdown)
    }, [])

    return (
        <nav className="navbar">
            <NavLink className="nav-link" to="/" style={{ color: 'inherit', textDecoration: 'inherit', listStyleType: 'none', display: 'contents' }}>
                <img className='brand-icon' src={QUTRC_Logo} alt="QUTRC Logo" />
                <h2 className='brand-name'>QUT Robotics Club</h2>

            </NavLink>


            <button className="hamburger" ref={btnRef} onClick={() => {
                setIsNavExpanded(!isNavExpanded);
            }}>
                {/* icon from heroicons.com */}
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="white"
                >
                    <path
                        fillRule="evenodd"
                        d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                        clipRule="evenodd"
                    />
                </svg>
            </button>
            <div
                className={
                    isNavExpanded ? "nav-tabs expanded" : "nav-tabs"
                }>
                {menuItems.map((item, index) => {
                    return (
                        <a key={index}
                            className={item.subMenu !== null ? 'nav-tab dropdown' : 'nav-tab'}
                            href={item.url}
                            onClick={() => setIsNavExpanded(false)}>
                            <div className='nav-title'>
                                <span>
                                    {item.title}{item.subMenu != null && <span className='dropdown-indicator'> ↓</span>}
                                </span>
                            </div>
                            {item.subMenu != null &&
                                <div className='dropdown-content'>
                                    {item.subMenu.map((subItem, index) => {
                                        return (
                                            <div key={index}><a href={subItem.url}>{subItem.title}</a></div>
                                        )
                                    })}
                                </div>
                            }
                        </a>
                    )
                })}
            </div>
        </nav>
    );
}