import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
display: flex;
justify-content: space-between;
border-bottom: 1px solid black;

button{
    margin-top: 15%;
}
`

// CSS code copied from https://getcssscan.com/css-buttons-examples (button 3)
const Button = styled.button`
  appearance: none;
  background-color: #112236;
  border: 1px solid rgba(27, 31, 35, .15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system,system-ui,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 6px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
`

const ResourceLink = (props) => {
    return (
        <Wrapper>
            <div>
                <h3>{props.children}</h3>
            </div>
            <div>
                <a href={props.href} target={ /* "_blank" TODO: is this neccessary */ ""} rel='noreferrer'>
                    <Button>{props.label}</Button>
                </a>
            </div>
        </Wrapper>
    )
}

export default ResourceLink