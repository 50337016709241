import React from "react";
import { Wrapper } from "../../../components/Wrapper.styles";

const ModellingWithCad = () => {
  return (
    <Wrapper>
      <h1>Mechanical Design and 3D Modelling with CAD</h1>
      <p>
        This workshop focuses on how you can use the many CAD software packages
        available, including SolidWorks and Inventor, to create models of your
        mechanical systems. The resources for this workshop are not ideal for
        self learning, as right now this presentation requires someone
        demonstrating how to use the software. More to be added soon.
      </p>
      <a
        href="https://qutrobotics.files.wordpress.com/2015/04/3d.pdf"
        target="_blank"
        rel="noreferrer"
      >
        3D Modelling
      </a>
      <p>Thanks to Karl Von Richter</p>
    </Wrapper>
  );
};

export default ModellingWithCad;
