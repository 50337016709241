import React from "react";
import { Wrapper } from "../../../components/Wrapper.styles";

const Suppliers = () => {
  return (
    <Wrapper>
      <h1>Suppliers</h1>
      <p>
        A basic guide to sourcing and pricing various components from local and
        international suppliers.
      </p>
      <p>By David Hedger.</p>
      <h3>Overview</h3>
      <p>
        Sourcing components is an often underestimated part of the engineering
        design process. You can easily spend an entire day or more tracking down
        just a few parts that need to fit very specific criteria. The aim of
        this guide is to let you know, as QUT students, what your options are,
        and what I’ve found in my experience as an engineering undergraduate.
      </p>
      <h3>Lead Time</h3>
      <p>
        One thing that every student trips up on at some point in their career
        is lead times. If you are presenting your device tomorrow, and you break
        something the night before, your options become extremely limited. You
        may find a physical shop somewhere that sells exactly what you need, but
        expect to pay far more than you would online for the privilege.
      </p>
      <p>
        Essentially, lead times and price tend to be inversely correlated. If
        you buy something direct from China, it will cost a fraction of what
        you’d get it for in a store, but it could take months to get here
        instead of being in your hands this afternoon. There are exceptions to
        this rule, especially if you buy in bulk, but for most small projects
        you won’t have that luxury.
      </p>
      <p>
        Another thing to look out for is the difference between shipping time
        and delivery time. You may have paid for DHL Overnight Super Express or
        whatever, but that only matters if the store actually gives the box to
        DHL on the same day. If you have a 1-7 day shipping time and a 3-7 day
        delivery time then the safe assumption is to add those two numbers
        together.
      </p>
      <p>
        The last thing you want is only doing proper testing a few weeks from
        the due date, and having a key module just burn out or prove inadequate
        under load. Keep your toolbox close and your lead times closer.
      </p>
      <h3>Suppliers</h3>
      <p>
        This is just a brief overview of a bunch of component and module
        suppliers. This section is almost entirely opinion and your experience
        may vary, so use this as a rough guide only.
      </p>
      <h3>Individual Electronic Components</h3>
      <p>
        When you’re looking for things like individual transistors or a
        particular integrated circuit, these guys are your friends. Individual
        components from these sites are often incredibly cheap, and are
        delivered with detailed tax invoices and shipping notes. They often
        service large industrial clients, so a lot of their pre-assembled
        products are focused towards that market. Don’t be scared off by
        extremely expensive power supplies and the like, they probably have
        industrial compliance requirements far above what you need in a simple
        robot.
      </p>
      <h3>Element14</h3>
      <p>
        Probably the best website of the bunch, relatively easy to navigate.
        Free overnight shipping on orders over $45. Average prices and range,
        but good enough for most purposes. Will happily split orders when some
        parts aren’t immediately available and ship them when they arrive.
      </p>
      <h3>RS Online</h3>
      <p>
        Difficult to use website, but free overnight shipping for all orders.
        Great if you really need just one part really quick. Sub-par range and
        prices, but sometimes has a couple of really niche parts for some
        strange reason.
      </p>
      <h3>Digikey</h3>
      <p>
        Has everything. If it’s not on Digikey it probably doesn’t exist.
        Website is average. Based in the US so longer lead times than most, but
        easily the best range and prices. Free shipping only on orders above
        $200 USD.
      </p>
      <h3>Mouser</h3>
      <p>
        Roughly on par with Element14, but with a different range and a slightly
        worse site. Free shipping on orders over $60.
      </p>
      <h2>Pre-Made Modules</h2>
      <h3>Pololu</h3>
      <p>
        Sell some very good power and motor management modules that simplify
        things when you can’t be bothered making your own. A good source for
        reliable enough motors in a bunch of practical sizes. Not much in the
        way of a community, but usually very well documented. Based in the US,
        with the associated lead times.
      </p>
      <h3>Sparkfun</h3>
      <p>
        Focus more on microcontroller style solutions. Data collection, Arduino
        shields, sensors, kits and LEDs. Very strong community with decent
        videos and tutorials. Most items have their schematics and layouts
        available to download. Also based in the US, so lead times.
      </p>
      <h3>Adafruit</h3>
      <p>
        These guys tend to make very flashy things. Adafruit are big on LEDs and
        wearable tech, but they also do quite a fair bit of motor control and
        software on the side. Really good tutorials on their products and also
        various electronic topics. Again, US, lead times.
      </p>
      <h3>LittleBird Electronics, Core Electronics, Robot Gear</h3>
      <p>
        These guys are basically Australian resellers for Pololu, Sparkfun and
        Adafruit. They don’t seem to produce anything of their own, but they
        have local stock, so they can have much shorter lead times. They also
        stock some parts from a whole range of other smaller manufacturers.
      </p>
      <h3>Jaycar</h3>
      <p>
        Having a local physical store like Jaycar is great, because you can drop
        in and have a look at things before you purchase. Of course the other
        advantage is being able to purchase things the same day you want them.
        They have an ok range, and their staff are usually helpful and
        knowledgeable, but are generally more expensive than online stores. If
        you can afford the lead time, it is often cheaper to use one of the
        above online suppliers first, but it can also be useful to go in to the
        store and physically see some of the components and how they fit
        together.
      </p>
    </Wrapper>
  );
};

export default Suppliers;
